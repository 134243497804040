import React from "react";
import { useLocale } from "../../../locales";
import styles from "./SideStats.module.scss";
import { sideStatsProps } from "./@types";
import { Text } from "../../atoms";
import { useWidth } from "@/utils/responsiveHook";

const SideStats = ({ users, bikes }: sideStatsProps) => {
  const { localeLabels, localeButtons } = useLocale();
  const { width } = useWidth();

  return (
    <div className="flex space-x-6  xl: justify-end ">
      <div className="flex items-center text-right text-lg lg:text-2xl leading-8 lg:leading-10 space-x-4">
        {users ? (
          <>
            <Text size={width <= 767 ? "xxs" : "s"}>
              {localeLabels.LABEL_NUM_OF_REGISTERED_USERS}
            </Text>
            <Text size={width <= 767 ? "s" : "l"} weight="700">
              {users}
            </Text>
          </>
        ) : (
          <>
            <Text size={width <= 767 ? "xxs" : "s"}>
              {localeLabels.LABEL_NUM_OF_MY_BIKES}
            </Text>
            <Text size={width <= 767 ? "s" : "l"} weight="700">
              {bikes}
            </Text>
          </>
        )}
      </div>
    </div>
  );
};

export default SideStats;

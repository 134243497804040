import { ModalProps } from "./@types";
import { IoIosCloseCircle } from "react-icons/io";
import styles from "./Modal.module.scss";
const Modal = ({ show, onHide, children, height = true,minHeight="500px"}: ModalProps) => {
  return (
    <>
      {show && (
        <div className="flex justify-center items-center fixed left-0 top-0 w-full h-screen overflow-auto bg-black bg-opacity-50 z-50 m-0">
          <>
            <div className="flex justify-center items-center">
              <div
                style={{
                  minHeight: minHeight,
                  // height:
                }}
                className={
                  styles["modal-card"] + " "+
                  !height
                    ? "bg-white   rounded-2xl  w-[300px] h-auto md:w-[450px]  lg:w-[500px] lg:h-[600px] flex flex-col justify-center items-center relative"
                    : "bg-white   rounded-2xl w-[300px] h-auto md:w-[450px]  lg:w-[500px] lg:h-[800px] flex flex-col justify-center items-center relative"
                }
                onClick={(e) => e.stopPropagation()}
              >
                {children}
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default Modal;

import React from "react";
import type { tableItemsProps } from "./@types";
import OrderTableItemsMobile from "../TableItems/OrderTableItemsMobile";
import { useWidth } from "@/utils/responsiveHook";
import TableItemsList from "../TableItems";

const TableItems = ({
  itemData,
  openModal,
  onClickDetails,
  tableType = "LIST_OF_USERS",
  navigateTo,
  onClickDeleteBike,
  onClickEdit,
}: tableItemsProps) => {
  const { width } = useWidth();
  console.log("itemData", itemData);

  switch (tableType) {
    case "LIST_OF_USERS":
      return width <= 767 ? (
        <OrderTableItemsMobile
          tableType={tableType}
          itemData={itemData}
          navigateTo={navigateTo}
        />
      ) : (
        <TableItemsList
          tableType={tableType}
          itemData={itemData}
          navigateTo={navigateTo}
        />
      );
    case "LIST_OF_BIKES":
      return width <= 767 ? (
        <OrderTableItemsMobile
          tableType={tableType}
          itemData={itemData}
          navigateTo={navigateTo}
        />
      ) : (
        <TableItemsList
          tableType={tableType}
          itemData={itemData}
          navigateTo={navigateTo}
          onClickDeleteBike={onClickDeleteBike}
          onClickEdit={onClickEdit}
        />
      );

    default:
      return null;
  }
};

export default TableItems;

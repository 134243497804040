import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import type { headerProps } from "./@types";
import { localeDropdowns, useLocale } from "../../../locales";
import DropDown from "../../molecules/DropDown";
import styles from "./Header.module.scss";
import { SlArrowDown } from "react-icons/sl";
import { IoArrowForward, IoMenuOutline } from "react-icons/io5";
import { FiChevronLeft } from "react-icons/fi";
import { Button, Text } from "@/components/atoms";
import { colorBgGray, colorBlack, colorWhite } from "@/styles/colors";
import useComponentVisibleDropdown from "@/utils/useCompositeDropdown";
import { useWidth } from "@/utils/responsiveHook";
import { useDisableBodyScroll } from "@/utils/usePreventScroll";

const Header = ({
  isAdmin = false,

  backArrow = false,
  headerPath = [],
  dashboard = "/",
  onEditAccount,
  onUpdatePassword,
  signOut,
}: headerProps) => {
  const { localeButtons, localeLabels, localeLinks, localeTitles } =
    useLocale();
  // const name = window.location.pathname.replaceAll("/", " ");
  const name = "Dashboard";

  const navigate = useNavigate();
  const { width } = useWidth();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const { scrollEnable, scrollDisable } = useDisableBodyScroll();

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisibleDropdown(false);
  const toggle = () => {
    if (isComponentVisible == true) {
      setIsComponentVisible(false);
    } else {
      setIsComponentVisible(true);
    }
  };
  useEffect(() => {
    if (isComponentVisible) {
      scrollDisable();
    } else {
      scrollEnable();
    }
  }, [isComponentVisible]);

  const titleType = () => {
    if (
      location.pathname.includes("dashboard") ||
      (location.pathname.length === 1 && location.pathname.startsWith("/"))
    )
      return localeLinks.LINK_DASHBOARD;
  };

  return (
    <div>
      <div className={styles["header"]}>
        <div className=" block lg:hidden " style={{ fontSize: "0.8rem" }}>
          <div
            onClick={() =>
              !location.pathname.includes("order-detail")
                ? navigate(dashboard)
                : navigate(-1)
            }
          >
            <div className="flex flex-col ">
              <div className="flex items-center">
                {backArrow && (
                  <FiChevronLeft
                    className="mr-2"
                    size={18}
                    color={width <= 991 ? "white" : "black"}
                  />
                )}
                <Text
                  size={"xs"}
                  weight="700"
                  color={width <= 991 ? "white" : "black"}
                >
                  {localeTitles.TITLE_BRAND}
                </Text>
              </div>
              <div className=" font-semibold">
                <Text
                  size={"10"}
                  weight="700"
                  color={width <= 991 ? "white" : "black"}
                >
                  {titleType()}
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden items-center space-x-3 font-medium lg:flex">
          {headerPath.length > 0 ? (
            <div className="flex items-center space-x-3">
              {headerPath.map((item, index) => {
                return (
                  <div
                    className="flex items-start hover:font-bold"
                    onClick={() => {
                      if (headerPath.length - 1 === index) {
                        return;
                      }
                      navigate(-(headerPath.length - index - 1));
                    }}
                  >
                    <div className="HeaderLink">
                      <Text size={"xs"}>{item}</Text>
                    </div>
                    <SlArrowDown
                      className="-rotate-90"
                      size={16}
                      style={{ marginTop: 5 }}
                    />
                  </div>
                );
              })}
            </div>
          ) : name ? (
            <div className="flex items-center space-x-3">
              <div>{name}</div>
              {/* <MdArrowForwardIos /> */}
              <IoArrowForward />
            </div>
          ) : null}
        </div>
        <div className="flex space-x-5 items-center">
          <DropDown
            isAdmin={isAdmin}
            onEditAccount={onEditAccount}
            onUpdatePassword={onUpdatePassword}
            signOut={signOut}
          />
          <div className="block lg:hidden">
            {!isComponentVisible && (
              <IoMenuOutline
                color="white"
                size={32}
                onClick={() => {
                  isComponentVisible == false
                    ? setIsComponentVisible(true)
                    : setIsComponentVisible(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {isComponentVisible && width < 1024 ? (
        <div
          ref={ref}
          className="bg-black text-white w-full z-50 fixed top-0 right-0 bg-opacity-100 mt-[48px]"
        >
          <div className=" flex flex-col w-full text-center ">
            <>
              <NavLink
                onClick={() => setIsComponentVisible(false)}
                to={"/users"}
                className={({ isActive }) =>
                  isActive
                    ? " w-full h-10 bg-link py-10 cursor-pointer  border-[#646464] border-b font-bold"
                    : "w-full h-10 py-10 cursor-pointer border-[#646464] border-b font-bold"
                }
              >
                <Text color="white" size={"s"} weight="700">
                  {localeLinks.LINK_LIST_OF_USERS}
                </Text>
              </NavLink>

              <NavLink
                onClick={() => setIsComponentVisible(false)}
                to={"/bikes"}
                className={({ isActive }) =>
                  isActive
                    ? " w-full h-10 bg-link py-10 cursor-pointer  border-[#646464] border-b font-bold"
                    : "w-full h-10 py-10 cursor-pointer  border-[#646464] border-b font-bold"
                }
              >
                <Text color="white" size={"s"} weight="700">
                  {localeLinks.LINK_LIST_OF_BIKES}
                </Text>
              </NavLink>

              <NavLink
                onClick={() => setIsComponentVisible(false)}
                to={"/categories"}
                className={({ isActive }) =>
                  isActive
                    ? " w-full h-10 bg-link py-10 cursor-pointer  border-[#646464] border-b font-bold"
                    : "w-full h-10 py-10 cursor-pointer  border-[#646464] border-b font-bold"
                }
              >
                <Text color="white" size={"s"} weight="700">
                  {localeLinks.LINK_CHARTS_SCREEN}
                </Text>
              </NavLink>

              <div className="m-auto w-48 h-12 my-24  flex justify-end cursor-pointer border-4">
                <Button
                  onClick={toggle}
                  color={colorWhite}
                  backgroundColor={colorBlack}
                  border={colorBgGray}
                  className="closeBtn"
                >
                  {localeButtons.BUTTON_CLOSE}
                </Button>
              </div>
            </>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Header;

import React, { useEffect, useRef, useState } from "react";
import { useLocale } from "../../../locales";
import styles from "./CommonTable.module.scss";
import { CommonTableProps } from "./@types";
import TableTemplate from "../../organisms/Table/Table";
import ConfirmationModal from "../../organisms/Confirmation Modal";
import TableItems from "../../molecules/Table Items";
import FilterModal from "../../organisms/Filter Modal";
import TableHeader from "@/components/molecules/Table Header";
import TableHeaderMobile from "@/components/molecules/Table Header/TableHeaderMobile";
import { useWidth } from "@/utils/responsiveHook";

const CommonTable = ({
  loading,
  data = [],
  tableType = "LIST_OF_USERS",
  showConfimationModal = false,
  onClickCancelBtnModal = () => {},
  onCLickConfirmBtnModal = () => Promise.resolve(),
  openModal = function (m, i) {},
  modalType = "",
  openSearchModal,
  onClickClearFilter,
  showFilter,
  navigateTo = () => {},
  onSubmit = () => {},
  filterLoading,
  nextFunction,
  previousFunction,
  confirmModalTitle,
  onClickEdit,
  ...props
}: CommonTableProps) => {
  const ref = useRef<any>(null);
  const { localeButtons } = useLocale();
  const { width } = useWidth();

  return (
    <div ref={ref}>
      <TableTemplate
        tableType={tableType}
        openSearchModal={openSearchModal}
        searchbtnShow={true}
        loading={loading}
        nextFunction={nextFunction}
        previousFunction={previousFunction}
        tableFooter={props.tableFooter}
        {...props}
        // tableHeading={<TableHeader tableType={tableType} />}
        tableHeading={
          width <= 767 ? (
            <TableHeaderMobile tableType={tableType} />
          ) : (
            <TableHeader tableType={tableType} />
          )
        }
        tableBody={data?.map((iterator, index) => (
          <TableItems
            key={index}
            itemData={iterator}
            tableType={tableType}
            openModal={openModal}
            navigateTo={navigateTo}
            onClickDeleteBike={onClickCancelBtnModal}
            onClickEdit={onClickEdit}
          />
        ))}
      />
      <ConfirmationModal
        title={confirmModalTitle}
        show={showConfimationModal}
        onHide={onClickCancelBtnModal}
        ModalType={modalType}
        onConfirm={onCLickConfirmBtnModal}
        // statusChangeModalLading={statusChangeModalLading}
      />
      <FilterModal
        filterType={tableType == "LIST_OF_USERS" ? "users" : "bikes"}
        loading={filterLoading}
        onSubmit={onSubmit}
        show={showFilter}
        onClickClearFilter={onClickClearFilter}
      />
    </div>
  );
};

export default CommonTable;

import React, { useState } from "react";
import "./App.css";
import AdminRoutes from "./Routes/Routes";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ToastProvider from "./store/ToastProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthAdminProvider from "./store/AuthProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CookiesProvider } from "react-cookie";
import LocaleProvider from "./locales";
import { Amplify } from 'aws-amplify';

// import { withAuthenticator } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';

// import awsExports from './aws-exports';
// Amplify.configure(awsExports);

const queryClient = new QueryClient();

function App() {
  document.cookie = "cookieAccepted=true";
  return (
    <div className="App">
      <CookiesProvider>
        <ToastContainer autoClose={2000} hideProgressBar closeButton={false} />
        <QueryClientProvider client={queryClient}>
          <ToastProvider>
            <LocaleProvider>
              <Router>
                <AuthAdminProvider>
                  <AdminRoutes />
                </AuthAdminProvider>
              </Router>
            </LocaleProvider>
          </ToastProvider>
        </QueryClientProvider>
      </CookiesProvider>
    </div>
  );
}

export default App;

import dayjs from "dayjs";

// return month number

export const getMonth = (date: Date) => {
  return dayjs(date).month() + 1;
};

// return year number

export const getYear = (date: Date) => {
  return dayjs(date).year();
};

export const getDate =(date:string) =>{
  return dayjs(date).format("YYYY-MM-DD")
}

// format date to YYYY-MM-DD

export const formatDate = (month: number, year: number) => {
  return dayjs(`${year}-${month}-01`).format("YYYY-MM-DD");
};

export const formatStringDateDot = (date: string) => {
  return dayjs(date).format("YYYY.MM.DD");
};
export const formatStringDateSlash = (date: string) => {
  return dayjs(date).format("YYYY/MM/DD");
};

export const LINKS = {
  en: {
    LINK_FORGOT_PW: "Forgot password",
    LINK_LOGIN: "Back to login page",
    LINK_DASHBOARD: "Dashboard",
    LINK_ORDERS: "List of Orders",
    LINK_EDIT_ACCOUNT: "Edit Account",
    LINK_LIST_OF_USERS: "List of users",
    LINK_USER_DETAILS: "User details",
    LINK_BIKE_DETAILS: "Bike details",
    LINK_LIST_OF_BIKES: "List of bikes",
    LINK_CHARTS_SCREEN: "Charts screen",
  },
  ja: {
    LINK_BIKE_DETAILS: "Bike details",
    LINK_USER_DETAILS: "User details",
    LINK_CHARTS_SCREEN: "カテゴリリスト",
    LINK_LIST_OF_BIKES: "バイクリスト",
    LINK_LIST_OF_USERS: "ユーザーリスト",
    LINK_EDIT_ACCOUNT: "Edit アカウントの編集",
    LINK_FORGOT_PW: "パスワードを忘れた方",
    LINK_LOGIN: "ログインページに戻る",
    LINK_DASHBOARD: "ダッシュボード",
    LINK_ORDERS: "注文リスト",
  },
};

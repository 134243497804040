import React from "react";
import type { InputProps } from "./@types";
import styles from "./Input.module.scss";

const Input = ({
  placeholder = ``,
  type = `text`,
  register,
  value,
  name,
  disabled = false,
  className,
  validation,
  width,
  ...props
}: InputProps) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      name={name}
      disabled={disabled}
      // className={`${styles[`input-field`]} ${className}`}
      className={
        width
          ? `border-black border-2 py-2 px-3 rounded-md  w-[100%] md:w-[160px] lg:w-[${width}] placeholder:text-black`
          : "border-black border-2 py-2 px-3 rounded-md w-full lg:w-full placeholder:text-[#969696]"
      }
      {...register&& register(name, validation)}
      // {...register}
      {...props}
    />
  );
};

export default Input;

import React, { useEffect, useState } from "react";
import type { tableProps } from "./@types";
import { useLocale } from "../../../locales";
import styles from "./Table.module.scss";
import { FcClearFilters } from "react-icons/fc";
import { AiOutlineSearch } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";

const TableWrpper = ({
  title,
  children,
  search,
  more,
  setSearch,
  path = "/admin/orderlist",
  className = " ",
  clearFilter,
  onClearfilter,
  clearFilterLoading,
  tableCalssName = "overflow-scroll",
}: tableProps) => {
  const { localeButtons, localeLabels } = useLocale();
  const navigate = useNavigate();
  const Navigate = () => {
    navigate(path);
  };

  return (
    <div className={"md:px-10 w-full   " + className}>
      <div className="bg-white w-full   rounded-[16px] mb-10">
        <div className=" flex justify-between items-center px-8 py-3 font-bold">
          <div className="text-base font-bold">{title}</div>
          <div className="items-center justify-center flex ">
            {clearFilter ? (
              <div
                className={
                  clearFilterLoading
                    ? "flex items-center space-x-3  px-2 p-1 rounded-[5px] cursor-progress"
                    : "flex items-center space-x-3  px-2 p-1 rounded-[5px] cursor-pointer"
                }
                onClick={() => {
                  if (!clearFilterLoading && onClearfilter) onClearfilter();
                }}
              >
                <div>{localeButtons.BUTTON_CLEAR_FILTER}</div>
                <FcClearFilters />
              </div>
            ) : null}
            {search ? (
              <div
                className="flex items-center space-x-3  px-2 p-1 rounded-[5px] cursor-pointer"
                onClick={() => {
                  setSearch();
                }}
              >
                <div>{localeLabels.LABEL_SEARCH}</div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.375023 2.415C3.40502 6.3 9.00002 13.5 9.00002 13.5V22.5C9.00002 23.325 9.67502 24 10.5 24H13.5C14.325 24 15 23.325 15 22.5V13.5C15 13.5 20.58 6.3 23.61 2.415C24.375 1.425 23.67 0 22.425 0H1.56002C0.315023 0 -0.389977 1.425 0.375023 2.415Z"
                    fill="black"
                  />
                </svg>
              </div>
            ) : null}
          </div>
        </div>
        <div className={tableCalssName}>{children}</div>
        {more ? (
          <div
            className="flex items-center justify-end space-x-3  px-2 p-2 rounded-[5px] cursor-pointer"
            onClick={Navigate}
          >
            <div className="font-bold mr-2 border-b-2">
              {localeLabels.LABEL_VIEW_MORE}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TableWrpper;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ToastConsumer } from "@/store/ToastProvider";
import { UsersContextConsumer } from "@/store/AuthProvider";
import { useQuery } from "react-query";
import { ApiFilterUsers, ApiGetAllUsers } from "@/utils/globalAPI/Dashboard";
import { SubmitHandler } from "react-hook-form";

export const useUsersList = () => {
  const toast = ToastConsumer();
  const navigate = useNavigate();
  const { adminData } = UsersContextConsumer();
  const [showFilter, setShowFilter] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [searchProvince, setSearchProvince] = useState("");
  const [searchGender, setSearchGender] = useState("");

  const [show, setShow] = useState(false);

  const onModal = () => {
    setShow(!show);
  };

  console.log("adminData", adminData);
  const {
    data: allUsers,
    isLoading: usersFetchLoading,
    error,
    refetch,
  } = useQuery(
    [
      "AllUsers",
      {
        adminData,
        searchProvince,
        searchGender,
      },
    ],
    async () => {
      if (!adminData?.signInUserSession?.accessToken?.jwtToken) {
        return; // Return early if the token is not available
      }

      if (searchGender || searchProvince) {
        try {
          const filteredUsers = await ApiFilterUsers(
            {
              province: searchProvince,
              gender: searchGender,
            },
            adminData.signInUserSession.accessToken.jwtToken
          );
          togleShowModalFilter();
          return filteredUsers.filterUser;
        } catch (error: any) {
          toast.error(error.message, "top-right");
          throw error; // Rethrow the error to be caught by the error handling logic
        }
      } else {
        try {
          const allUsers = await ApiGetAllUsers(
            adminData.signInUserSession.accessToken.jwtToken
          );

          return allUsers.userList;
        } catch (error: any) {
          toast.error(error.message, "top-right");
          throw error; // Rethrow the error to be caught by the error handling logic
        }
      }
    }
  );

  const navigateToUserDetails = (userId: string) => {
    navigate("/users/details?userId=" + userId);
  };

  const togleShowModalFilter = () => {
    setShowFilter(!showFilter);
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    setFilterLoading(true);
    setSearchProvince(data?.province);
    setSearchGender(data?.gender);
  };

  return {
    show,
    onModal,
    allUsers,
    usersFetchLoading,
    navigateToUserDetails,
    togleShowModalFilter,
    showFilter,
    onSubmit,
  };
};

import React from 'react';
import { toast } from 'react-toastify';
import { createCtx } from '../utils';

import { ToastContext as ToastContextType } from './@types';

export const [useToast, SetToastProvider] = createCtx<ToastContextType>();
type positionType = 'top-right'| 'top-center'| 'top-left'| 'bottom-right'| 'bottom-center'| 'bottom-left'
const useToastCtx = () => {
  const success = (title: string,position?:positionType) => toast.success(title,{position:position})

  const error = (title: string,position?:positionType) => toast.error(title,{position:position})

  const warning = (title: string, position?:positionType) => toast.warning(title,{position:position})

  const notice = (title: string,position?:positionType) => toast.info(title,{position:position})

  return { success, error, warning, notice };
};

const ToastProvider = ({ children }: { children: JSX.Element }) => {
  const toast:any = useToastCtx();
  return <SetToastProvider value={toast}>{children}</SetToastProvider>;
};

export const ToastConsumer = () => {
  const toast = useToastCtx();
  return toast;
};

export default ToastProvider;

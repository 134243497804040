import React from "react";

type Props = { white?: boolean; size?: "MOBILE" | "TABLET" | "LARGE" };
function CancelBtnIcon({ white, size }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size == "MOBILE" ? "15" : "20"}
      height={size == "MOBILE" ? "15" : "21"}
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        fill={white ? "white" : "#646464"}
        d="M5.7 14.8a.948.948 0 00.7.275.948.948 0 00.7-.275l2.9-2.9 2.925 2.925c.183.183.413.27.688.262a.975.975 0 00.687-.287.948.948 0 00.275-.7.948.948 0 00-.275-.7l-2.9-2.9 2.925-2.925a.891.891 0 00.262-.688.975.975 0 00-.287-.687.948.948 0 00-.7-.275.948.948 0 00-.7.275L10 9.1 7.075 6.175a.893.893 0 00-.687-.263.98.98 0 00-.688.288.948.948 0 00-.275.7c0 .283.092.517.275.7l2.9 2.9-2.925 2.925a.89.89 0 00-.262.687.976.976 0 00.287.688zm4.3 5.7a9.733 9.733 0 01-3.9-.788 10.092 10.092 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.732 9.732 0 010 10.5c0-1.383.263-2.683.788-3.9a10.092 10.092 0 012.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0110 .5c1.383 0 2.683.262 3.9.787a10.105 10.105 0 013.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0120 10.5a9.733 9.733 0 01-.788 3.9 10.092 10.092 0 01-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137a9.733 9.733 0 01-3.9.788z"
      ></path>
    </svg>
  );
}

export default CancelBtnIcon;

import { ImageProps } from "./@types";
import React from "react";

const Image = ({
  imagePath,
  alt,
  size = "default",
  width = 375,
  height = 637,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <img
      style={props.imageStyle}
      src={imagePath}
      alt={alt}
      width={width}
      height={height}
      {...props}
    />
  );
};

Image.defaultProps = {
  alt: "image",
  size: "default",
  width: 375,
  height: 637,
  imagePath:
    "https://images.unsplash.com/photo-1616161616161-1a1a1a1a1a1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=375&q=80",
};

export default Image;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ToastConsumer } from "@/store/ToastProvider";
import { UsersContextConsumer } from "@/store/AuthProvider";
import { useQuery } from "react-query";
import {
  ApiGetCategoriesData,
  ApiGetCategoryBlogsMonthly,
  ApiGetCategoryPostsMonthly,
  ApiGetCategoryThreadsMonthly,
  ApiGetCategoryTouringMonthly,
} from "@/utils/globalAPI/Dashboard";
import { localeLabels } from "@/locales";

export const useCategoryChartList = () => {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const stringCurrent = `${currentYear}-${currentMonth}`;
  const toast = ToastConsumer();
  const navigate = useNavigate();
  const [monthlyDate, setMonthlyDate] = useState<string>(stringCurrent);
  const [postMonthLyData, setPostMonthLyData] = useState<any>([]);
  const [blogMonthLyData, setBlogMonthLyData] = useState<any>([]);
  const [threadMonthLyData, setThreadMonthLyData] = useState<any>([]);
  const [touringMonthLyData, setTouringMonthLyData] = useState<any>([]);
  const [postMonthLyDataLoading, setPostMonthLyDataLoading] =
    useState<boolean>(false);
  const [BlogMonthLyDataLoading, setBlogMonthLyDataLoading] =
    useState<boolean>(false);
  const [threadMonthLyDataLoading, setThreadMonthLyDataLoading] =
    useState<boolean>(false);
  const [touringMonthLyDataLoading, setTouringMonthLyDataLoading] =
    useState<boolean>(false);
  const { adminData } = UsersContextConsumer();

  const [show, setShow] = useState(false);
  const excludedCategories = ["all", "myBikes"];

  const onModal = () => {
    setShow(!show);
  };

  const {
    data: allCategories,
    isLoading: categoriesFetchLoading,
    error,
    refetch,
  } = useQuery(
    [
      "AllCategories",
      {
        adminData,
      },
    ],
    async () =>
      ApiGetCategoriesData(adminData?.signInUserSession?.accessToken?.jwtToken)
        .then((res) => res.categoriesList)
        .catch((error) => console.log("error", error))
  );

  useEffect(() => {
    if (adminData?.signInUserSession?.accessToken?.jwtToken) {
      getMonthlyData(monthlyDate, "POST");
      getMonthlyData(monthlyDate, "BLOGS");
      getMonthlyData(monthlyDate, "THREADS");
      getMonthlyData(monthlyDate, "TOURING");
    }
  }, [adminData]);

  const getMonthlyData = async (
    date: string,
    type?: "POST" | "BLOGS" | "THREADS" | "TOURING"
  ) => {
    if (!adminData?.signInUserSession?.accessToken?.jwtToken) {
      return; // Return early if the token is not available
    }

    let stringMonth = date.toString().split("-")[1];
    let stringYear = date.toString().split("-")[0];
    let stringDate = `${stringYear}-${stringMonth}`;

    setMonthlyDate(stringDate);
    setMonthlyDate(monthlyDate);

    if (type === "POST") {
      setPostMonthLyDataLoading(true);
      try {
        const response = await ApiGetCategoryPostsMonthly(
          stringDate,
          adminData.signInUserSession.accessToken.jwtToken
        );

        if (response?.status === 200) {
          setPostMonthLyData(response?.categoryPosts);
          setPostMonthLyDataLoading(false);
        } else {
          setPostMonthLyDataLoading(false);
        }
      } catch (e: any) {
        // refreTokenOnRequestFailed(e, () => getMonthlyDatas(date));
      }
    }

    if (type === "BLOGS") {
      setBlogMonthLyDataLoading(true);
      try {
        const response = await ApiGetCategoryBlogsMonthly(
          stringDate,
          adminData.signInUserSession.accessToken.jwtToken
        );

        if (response?.status === 200) {
          setBlogMonthLyData(response?.categoryBlogs);
          setBlogMonthLyDataLoading(false);
        } else {
          setBlogMonthLyDataLoading(false);
        }
      } catch (e: any) {
        // refreTokenOnRequestFailed(e, () => getMonthlyDatas(date));
      }
    }

    if (type === "THREADS") {
      setThreadMonthLyDataLoading(true);
      try {
        const response = await ApiGetCategoryThreadsMonthly(
          stringDate,
          adminData.signInUserSession.accessToken.jwtToken
        );

        if (response?.status === 200) {
          setThreadMonthLyData(response?.categoryThreads);
          setThreadMonthLyDataLoading(false);
        } else {
          setThreadMonthLyDataLoading(false);
        }
      } catch (e: any) {
        // refreTokenOnRequestFailed(e, () => getMonthlyDatas(date));
      }
    }

    if (type === "TOURING") {
      setTouringMonthLyDataLoading(true);
      try {
        const response = await ApiGetCategoryTouringMonthly(
          stringDate,
          adminData.signInUserSession.accessToken.jwtToken
        );

        if (response?.status === 200) {
          setTouringMonthLyData(response?.touringLengths);
          setTouringMonthLyDataLoading(false);
        } else {
          setTouringMonthLyDataLoading(false);
        }
      } catch (e: any) {
        // refreTokenOnRequestFailed(e, () => getMonthlyDatas(date));
      }
    }
  };

  // if (adminData?.signInUserSession?.accessToken?.jwtToken) {
  //   // Run your code here that should execute when the token is available
  //   // For example, you can call the getMonthlyData function here
  //   getMonthlyData(date, type);
  // }

  // const getMonthlyData = async (
  //   date: string,
  //   type?: "POST" | "BLOGS" | "THREADS" | "TOURING"
  // ) => {
  //   let stringMonth = date.toString().split("-")[1];
  //   let stringYear = date.toString().split("-")[0];
  //   let stringDate = `${stringYear}-${stringMonth}`;

  //   setMonthlyDate(stringDate);
  //   setMonthlyDate(monthlyDate);
  //   if (type === "POST") {
  //     setPostMonthLyDataLoading(true);
  //     try {
  //       const response = await ApiGetCategoryPostsMonthly(
  //         stringDate,
  //         adminData?.signInUserSession?.accessToken?.jwtToken
  //       );
  //       // console.log("response", response);
  //       if (response?.status === 200) {
  //         setPostMonthLyData(response?.categoryPosts);
  //         setPostMonthLyDataLoading(false);
  //       } else {
  //         setPostMonthLyDataLoading(false);
  //       }
  //     } catch (e: any) {
  //       // refreTokenOnRequestFailed(e, () => getMonthlyDatas(date));
  //     }
  //   }
  //   if (type === "BLOGS") {
  //     setBlogMonthLyDataLoading(true);
  //     try {
  //       const response = await ApiGetCategoryBlogsMonthly(
  //         stringDate,
  //         adminData?.signInUserSession?.accessToken?.jwtToken
  //       );
  //       // console.log("response", response);
  //       if (response?.status === 200) {
  //         setBlogMonthLyData(response?.categoryBlogs);
  //         setBlogMonthLyDataLoading(false);
  //       } else {
  //         setBlogMonthLyDataLoading(false);
  //       }
  //     } catch (e: any) {
  //       // refreTokenOnRequestFailed(e, () => getMonthlyDatas(date));
  //     }
  //   }
  //   if (type === "THREADS") {
  //     setThreadMonthLyDataLoading(true);
  //     try {
  //       const response = await ApiGetCategoryThreadsMonthly(
  //         stringDate,
  //         adminData?.signInUserSession?.accessToken?.jwtToken
  //       );

  //       if (response?.status === 200) {
  //         setThreadMonthLyData(response?.categoryThreads);
  //         setThreadMonthLyDataLoading(false);
  //       } else {
  //         setThreadMonthLyDataLoading(false);
  //       }
  //     } catch (e: any) {
  //       // refreTokenOnRequestFailed(e, () => getMonthlyDatas(date));
  //     }
  //   }
  //   if (type === "TOURING") {
  //     setTouringMonthLyDataLoading(true);
  //     try {
  //       const response = await ApiGetCategoryTouringMonthly(
  //         stringDate,
  //         adminData?.signInUserSession?.accessToken?.jwtToken
  //       );
  //       if (response?.status === 200) {
  //         setTouringMonthLyData(response?.touringLengths);
  //         setTouringMonthLyDataLoading(false);
  //       } else {
  //         setTouringMonthLyDataLoading(false);
  //       }
  //     } catch (e: any) {
  //       // refreTokenOnRequestFailed(e, () => getMonthlyDatas(date));
  //     }
  //   }
  // };

  const chartOptions = {
    chart: {
      stacked: true,
      // id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
      },
    },
    xaxis: {
      // categories: allCategories
      //   ?.filter((data: any) => {
      //     return data.categoryName !== "All" && data.categoryName !== "MyBike";
      //   })
      //   .map((data: any) => data.categoryName),
      categories: [
        localeLabels.LABEL_MAINTAINCE,
        localeLabels.LABEL_TOURING,
        localeLabels.LABEL_GOURMET,
        localeLabels.LABEL_RACE_CIRCUIT,
        localeLabels.LABEL_APPAREL,
        localeLabels.LABEL_MY_BIKE,
      ],
    },

    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      horizontalAlign: "left",

      markers: {
        radius: 4,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 5,
      },
    },
  };

  const chartListoptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
      },
    },
    xaxis: {
      tickAmount: 6,
      categories: touringMonthLyData
        .filter((data: any) => [1, 10, 20, 30].includes(data.date)) // Filter for specific dates
        .map((data: any) => data.date),
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      horizontalAlign: "left",
      markers: {
        radius: 4,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 5,
      },
    },
    markers: {
      size: 8,
    },
  };

  const postData = {
    series: [
      {
        name: "Feeds",
        // data: [30, 40, 45, 50, 49, 60, 70, 91],
        // data: postMonthLyData.map((data: any) => data.totalPosts),
        data: postMonthLyData
          ?.filter((data: any) => {
            return (
              data.categoryName !== "All" && data.categoryName !== "MyBike"
            );
          })
          .map((data: any) => data.totalPosts),

        color: "rgba(104, 216, 238, 0.4)",
      },
    ],
  };
  const blogData = {
    series: [
      {
        name: "Blogs",
        // data: [30, 40, 45, 50, 49, 60, 70, 91],
        // data: blogMonthLyData.map((data: any) => data.total),
        data: blogMonthLyData
          ?.filter((data: any) => {
            return (
              data.categoryName !== "All" && data.categoryName !== "MyBike"
            );
          })
          .map((data: any) => data.total),

        color: "rgba(104, 216, 238, 0.4)",
      },
    ],
  };
  const threadData = {
    series: [
      {
        name: "Threads",
        // data: [30, 40, 45, 50, 49, 60, 70, 91],
        // data: threadMonthLyData.map((data: any) => data.totalPosts),
        // data: threadMonthLyData
        //   ?.filter((data: any) => {
        //     return (
        //       data.categoryName !== "All" && data.categoryName !== "MyBike"
        //     );
        //   })
        //   .map((data: any) => data.totalPosts),
        data: [
          "マイバイク",
          "フォロー中",
          "マイバイク",
          "フォロー中",
          "マイバイク",
          "フォロー中",
        ],
        color: "rgba(104, 216, 238, 0.4)",
      },
    ],
  };

  const touringData = {
    series: [
      {
        name: "series-1",
        data: touringMonthLyData
          .filter((data: any) => [1, 10, 20, 30].includes(data.date)) // Filter for specific dates
          .map((data: any) => data.totalTouring),
      },
    ],
  };

  let result = postMonthLyData.map((a: any) => a.categoryName);

  console.log("postMonthLyData", postMonthLyData);
  console.log("threadMonthLyData", threadMonthLyData);
  console.log("threadMonthLyData", threadMonthLyData);
  console.log("touringMonthLyData", touringMonthLyData);
  console.log("allCategories", allCategories);

  return {
    show,
    onModal,
    categoriesFetchLoading,
    getMonthlyData,
    postMonthLyDataLoading,
    BlogMonthLyDataLoading,
    threadMonthLyDataLoading,
    touringMonthLyDataLoading,
    chartOptions,
    chartListoptions,
    postData,
    touringData,
    blogData,
    threadData,
  };
};

import { useEffect, useRef, useState } from "react";
import { useLocale } from "../../../locales";
import Text from "@/components/atoms/Text";

import type { UserBioProps } from "./@types";
import styles from "./UserBio.module.scss";
import { useWidth } from "@/utils/responsiveHook";
import Loader from "@/components/molecules/Loading";
import Image from "@/components/atoms/Image";
import frame from "../../../assets/images/Frame.png";

const UserBio = ({ details, loading }: UserBioProps) => {
  const { localeLabels } = useLocale();
  const ref = useRef<any>(null);
  const { width } = useWidth();

  // useEffect(() => {
  //   window.onload = () => {
  //     if (ref.current != null) {
  //       if (window.innerWidth > 769) {
  //         ref.current.style.width = "550px";
  //       } else if (window.innerWidth > 450 && window.innerWidth < 768) {
  //         ref.current.style.width = "400px";
  //       } else if (window.innerWidth < 449) {
  //         ref.current.style.width = window.innerWidth - 20 + "px";
  //       }
  //     }
  //   };
  //   window.addEventListener("resize", () => {
  //     if (ref.current != null) {
  //       if (window.innerWidth > 769) {
  //         ref.current.style.width = "550px";
  //       } else if (window.innerWidth > 450 && window.innerWidth < 768) {
  //         ref.current.style.width = "400px";
  //       } else if (window.innerWidth < 449) {
  //         ref.current.style.width = window.innerWidth - 20 + "px";
  //       }
  //     }
  //   });
  // }, [width]);
  console.log("details", details);

  return (
    <div ref={ref} className="">
      {loading ? (
        <div className="flex justify-center items-center h-full w-full">
          <Loader />
        </div>
      ) : (
        <>
          <div className="mb-8 justify-center flex">
            {details?.image !== null && (
              <Image
                imagePath={
                  details?.image[0]?.filePath
                    ? details?.image[0]?.filePath
                    : frame
                }
                imageStyle={{
                  width: "120px",
                  height: "120px",
                  objectFit: "cover",
                  borderRadius: "64px",
                }}
              />
            )}
          </div>
          <div className="flex space-x-10">
            <div className="flex flex-col space-y-2 font-medium ">
              <Text size={"xxs"} weight="500">
                {localeLabels.LABEL_NICKNAME}
              </Text>
              <Text size={"xxs"} weight="500">
                {localeLabels.LABEL_PROVINCE}
              </Text>
              <Text size={"xxs"} weight="500">
                {localeLabels.LABEL_GENDER}
              </Text>
              <Text size={"xxs"} weight="500">
                {localeLabels.LABEL_NUM_OF_FOLLOWS}
              </Text>
              <Text size={"xxs"} weight="500">
                {localeLabels.LABEL_NUM_OF_FOLLOWERSS}
              </Text>
              <Text size={"xxs"} weight="500">
                {localeLabels.LABEL_LAST_LOGIN}
              </Text>
            </div>
            <div className="flex flex-col space-y-2 font-black">
              <Text size={"s"} weight="700">
                {details?.nickName}
              </Text>
              <Text size={"s"} weight="700">
                {details?.province}
              </Text>
              <Text size={"s"} weight="700">
                {details?.gender}
              </Text>
              <Text size={"s"} weight="700">
                {!details?.numOfFollows ? 0 : details?.numOfFollows}
              </Text>
              <Text size={"s"} weight="700">
                {!details?.numOfFollowers ? 0 : details?.numOfFollowers}
              </Text>
              <Text size={"s"} weight="700">
                {details?.lastLogin}
              </Text>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserBio;

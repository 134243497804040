import { Text } from "@/components/atoms";
import React from "react";
import { useLocale } from "../../../locales";
import type { tableHeaderProps } from "./@types";

const TableHeaderMobile = ({
  tableType = "LIST_OF_USERS",
}: tableHeaderProps) => {
  const { localeLabels } = useLocale();
  switch (tableType) {
    case "LIST_OF_USERS":
      return (
        <tr className="bg-black">
          <th className="font-bold text-left   pt-3 pb-3 pl-2 " colSpan={1}>
            <Text size={"10"} color="white">
              {localeLabels.LABEL_NICKNAME}
            </Text>
          </th>
          <th className="font-bold text-left   pt-3 pb-3 pl-2 " colSpan={1}>
            <Text size={"10"} color="white">
              {localeLabels.LABEL_PROVINCE}
            </Text>
          </th>

          <th
            className=" font-bold text-center bg-black  pt-3 pb-3 "
            colSpan={2}
          >
            <Text size={"10"} color="white">
              {localeLabels.LABEL_ORDER_STATUS}
            </Text>
          </th>
          <th className=" font-bold text-center bg-black  pt-3 pb-3">
            <Text size={"10"} color="white">
              {""}
            </Text>
          </th>
        </tr>
      );

    case "LIST_OF_BIKES":
      return (
        <>
          <tr className="bg-black">
            <th
              className="font-bold text-left bg-black text-white pt-3 pb-3 pl-2 text-[10px]"
              colSpan={1}
            >
              <Text size={"10"} color="white">
                {localeLabels.LABEL_BIKE_NAME}
              </Text>
            </th>
            <th
              className="font-bold text-left bg-black text-white pt-3 pb-3 pl-2 text-[10px]"
              colSpan={1}
            >
              <Text size={"10"} color="white">
                {localeLabels.LABEL_MAKER}
              </Text>
            </th>
            <th
              className=" font-bold text-left bg-black text-white pt-3 pb-3 pl-2 text-[10px]"
              colSpan={1}
            >
              <Text size={"10"} color="white">
                {localeLabels.LABEL_EMISSION}
              </Text>
            </th>

            <th
              className=" font-bold text-center bg-black text-white pt-3 pb-3"
              colSpan={1}
            ></th>
          </tr>
        </>
      );

    default:
      return null;
  }
};

//   }
// };

export default TableHeaderMobile;

export const SUCCESS = {
  en: {
    SUCCESS_LOGIN: "Successfully Logged in",
    SUCCESS_PW_RESET: "Successfully email is sent",
    SUCCESS_CHANGE_PW: "Successfully password is changed",
    SUCCESS_EDIT_ACCOUNT:"Successfully update account infomation",
    SUCCESS:"Success",
    SUCCESS_CREATE_ORDER:"Successfully create order",
    SUCCESS_APPLY_FILTER:"Successfully filter applied", 
    SUCCESS_ORDER_DELIVERED:"Successfully order delivered",
    SUCCESS_ORDER_SHIPPED:"Successfully shipped order",
    SUCCESS_ORDER_ISSUED:"Successfully report issue",
    SUCCESS_ORDER_CANCELLED:"Successfully cancel order",
  },
  ja: {
    SUCCESS_LOGIN: "ログインに成功しました",
    SUCCESS_PW_RESET: "メールを送信しました",
    SUCCESS_CHANGE_PW: "パスワードが変更されました",
    SUCCESS_EDIT_ACCOUNT:"アカウント情報の変更が完了しました",
    SUCCESS:"Sucess",
    SUCCESS_CREATE_ORDER:"発注が完了しました",
    SUCCESS_APPLY_FILTER:"絞り込みを反映しました",
    SUCCESS_ORDER_DELIVERED:"発注の納品が完了しました",
    SUCCESS_ORDER_SHIPPED:"発注を配送中へ変更しました",
    SUCCESS_ORDER_ISSUED:"発注の不具合を報告しました",
    SUCCESS_ORDER_CANCELLED:"発注のキャンセルが完了しました",
  },
};

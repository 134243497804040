import { useState } from "react";

import Modal from "../../molecules/Modal";
import type { EditBikeModalProps } from "./@types";
import styles from "./EditBikeModal.module.scss";
import EditBikeForm from "../EditBikeForm";

const EditBikeModal = ({
  show,
  onHide,
  onSubmit,
  addBikeLoading,
  bikeData
}: EditBikeModalProps) => {
  return (
    <div className={styles["auth-form"]}>
      <Modal show={show}>
        <EditBikeForm
          onSubmit={onSubmit}
          onHide={onHide}
          loading={addBikeLoading}
          bikeData={bikeData}
        />
      </Modal>
    </div>
  );
};

export default EditBikeModal;

import React, { useEffect, useState } from "react";
import { useLocale } from "../../../locales";
import styles from "./MonthlyOrders.module.scss";
import { monthlyOrdersProps } from "./@types";
import { formatDate, getMonth, getYear } from "../../../utils/days";
import Loader from "../../molecules/Loading";
import { Text } from "../../atoms";
import { FaArrowLeft, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useWidth } from "@/utils/responsiveHook";

let dmmyFunction = (date: string, type: string) => {
  console.log(date, type);
};
const MonthlyOrders = ({
  monthlyOrders,
  getMonthlyData = dmmyFunction,
  disabled = false,
  extraClass = "",
  heading,
}: monthlyOrdersProps) => {
  const {
    localeButtons,
    localeDropdowns,
    localeTitles,
    localeLogos,
    localeLabels,
  } = useLocale();
  const { width } = useWidth();

  const [month, setMonth] = React.useState(getMonth(new Date()));
  const [year, setYear] = React.useState(getYear(new Date()));
  const prevmonth = () => {
    if (disabled) return;
    let date;
    if (month === 1) {
      setMonth(12);
      date = formatDate(12, year - 1);
      setYear(year - 1);
    } else {
      date = formatDate(month - 1, year);
      setMonth(month - 1);
    }
    getMonthlyData(date, heading);
  };
  const nextmonth = () => {
    if (disabled) return;
    let date;
    if (month === 12) {
      setMonth(1);
      date = formatDate(1, year + 1);
      setYear(year + 1);
    } else {
      date = formatDate(month + 1, year);
      setMonth(month + 1);
    }
    getMonthlyData(date, heading);
  };

  return (
    <div className="justify-center items-center flex w-full">
      <div className="w-[95vw] h-[160px] sm:w-[450px] md:w-[558px] md:h-[163px] lg:h-full p-[15px] lg:w-[80%] rounded-lg space-y-5">
        <div className="flex justify-between items-center text-sm font-bold pt-0 font-base lg:text-base  lg:pt-5">
          <div
            onClick={prevmonth}
            className={
              disabled
                ? "cursor-progress flex items-center space-x-2  flex-row"
                : "flex items-center space-x-2 cursor-pointer flex-row"
            }
          >
            <Text size={"xs"} color="black" weight="700">
              {localeButtons.BUTTON_PREV_MONTH}
            </Text>

            <div>
              <FaChevronLeft color="black" />
            </div>
          </div>
          <div className="flex space-x-2 mx-2 lg:mx-10">
            <div>
              <Text size={width <= 767 ? "s" : "m"} color="black" weight="700">
                {year}
                {localeLabels.LABEL_Y}
              </Text>
            </div>
            <div>
              <Text size={width <= 767 ? "s" : "m"} weight="700" color="black">
                {month}
                {localeLabels.LABEL_M}
              </Text>
            </div>
          </div>
          <div
            onClick={nextmonth}
            className={
              disabled
                ? "cursor-progress flex items-center space-x-2  flex-row"
                : "flex items-center space-x-2 cursor-pointer flex-row"
            }
          >
            <FaChevronRight color="black" />

            <Text
              size={"xs"}
              color="black"
              weight="700"
              // className="text-[16px] font-bold text-white"
            >
              {localeButtons.BUTTON_NEXT_MONTH}
            </Text>
          </div>
        </div>
        {/* <div className="text-5xl text-center space-y-5 lg:text-6xl">
          {!disabled ? (
            <Text
              color="white"
              weight="700"
              // className="font-bold text-white"
            >
              {monthlyOrders}
            </Text>
          ) : (
            <Loader color="#fff" style={{ width: "100%" }} />
          )}

          <Text
            size="m"
            color="white"
            //  className=" text-white text-[20px]"
          >
            {localeLabels.LABEL_MONTHLY_ORDERS}
          </Text>
        </div> */}
      </div>
    </div>
  );
};

export default MonthlyOrders;

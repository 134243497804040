import { addNewBikeForm } from "@/types/Gloabal";
import apiRequest from "@/utils/axios";

export const ApiGetAllBikes = async (token: string) => {
  try {
    const result = await apiRequest({
      method: "GET",
      url: "/admin/dashboard/getAllBikes",
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiGetAllUsers = async (token: any) => {
  console.log("token", token);
  try {
    const result = await apiRequest({
      method: "GET",
      url: "/admin/dashboard/getAllUsers",
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiGetUserDetails = async (userId: string, token: string) => {
  try {
    const result = await apiRequest({
      method: "GET",
      url: `/admin/dashboard/userDetail/${userId}`,
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiGetUserBikes = async (userId: string, token: string) => {
  try {
    const result = await apiRequest({
      method: "GET",
      url: `/admin/dashboard/getUserBikes/${userId}`,
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

// export const ApiGetAllCategories = async (token: string) => {
//   try {
//     const result = await apiRequest({
//       method: "GET",
//       url: "/admin/dashboard/allCategories",
//       data: token,
//     });
//     return Promise.resolve(result);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };

export const ApiAddNewBike = async (data: addNewBikeForm, token: string) => {
  // console.log("APIData", data);
  try {
    const result = await apiRequest({
      method: "POST",
      url: "/admin/dashboard/addBike",
      data,
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiEditBike = async (data: any, token: string, id: string) => {
  console.log("ApiEditBike", data, "id", id);
  try {
    const result = await apiRequest({
      method: "PUT",
      url: `/admin/dashboard/bike/${id}`,
      data,
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiDeleteBike = async (id: string, token: string) => {
  console.log("APIData", id, token);
  try {
    const result = await apiRequest({
      method: "DELETE",
      url: `/admin/dashboard/bike/${id}`,
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiAddNewCategory = async (
  data: addNewBikeForm,
  token: string
) => {
  try {
    const result = await apiRequest({
      method: "POST",
      url: "/admin/dashboard/addBike",
      data,
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiGetCategoryPostsMonthly = async (
  month: string,
  token: string
) => {
  console.log("APIData", month);
  try {
    const result = await apiRequest({
      method: "GET",
      url: `/admin/dashboard/category/posts/${month}`,
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiGetCategoryBlogsMonthly = async (
  month: string,
  token: string
) => {
  console.log("APIData", month);
  try {
    const result = await apiRequest({
      method: "GET",
      url: `/admin/dashboard/category/blogs/${month}`,
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiGetCategoryThreadsMonthly = async (
  month: string,
  token: string
) => {
  try {
    const result = await apiRequest({
      method: "GET",
      url: `/admin/dashboard/category/threads/${month}`,
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiGetCategoryTouringMonthly = async (
  month: string,
  token: string
) => {
  console.log("ApiGetCategoryTouringMonthly", month);
  try {
    const result = await apiRequest({
      method: "GET",
      url: `/admin/dashboard/category/touring/${month}`,
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiGetCategoriesData = async (token: string) => {
  try {
    const result = await apiRequest({
      method: "GET",
      url: "/admin/dashboard/category",
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiDeactivateUser = async (
  userId: string,
  data: any,
  token: string
) => {
  console.log("ApiDeactivateUser", userId, "data", data, "token", token);
  try {
    const result = await apiRequest({
      method: "POST",
      url: `/admin/dashboard/userDeactivate/${userId}`,
      data: data,
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiFilterUsers = async (data: any, token: string) => {
  try {
    const result = await apiRequest({
      method: "POST",
      url: `/admin/dashboard/users/filter`,
      data: data,
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiFilterBikes = async (data: any, token: string) => {
  try {
    const result = await apiRequest({
      method: "POST",
      url: `/admin/dashboard/bikes/filter`,
      data: data,
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

import React, { useEffect, useRef } from "react";

import Header from "../../organisms/Header";
import Sidebar from "../../organisms/Sidebar";
import UpdatePasswordModal from "../../organisms/Update Password Modal";
import { adminLayoutProps } from "./@types";
import styles from "./Layout.module.scss";

import { useLayout } from "./hook";
import EditAccountModal from "@/components/organisms/Edit Account Modal/EditAccountModal";
import { useHeight } from "@/utils/responsiveHook";
import { Amplify,Auth } from "aws-amplify";

import {
  withAuthenticator,
  WithAuthenticatorProps,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
// import { currentConfig } from "@/Configuration";
import awsExports from "@/Configuration";

Amplify.configure(awsExports);
// Auth.configure(awsExports);

interface Props extends WithAuthenticatorProps {
  isPassedToWithAuthenticator: boolean;
}

function AdminLayout({
  children,
  dashboard,
  isProtected = true,
  isAdmin = false,

  headerPath = [],
  backArrow = false,
  signOut,
  user,
}: adminLayoutProps) {
  const {
    setShow,
    editAccountModal,
    updatePasswordModal,
    dashboardPath,
    onEditAccount,
    onUpdatePassword,
    accountRegister,
    accountHandleSubmit,
    accountErrors,
    onSubmitAccountForm,
    resetAccountForm,
    loading,
    updateAdmin,
    adminData,
    setAdminData,
  } = useLayout(isAdmin, isProtected);
  const { height } = useHeight();

  useEffect(() => {
    if (user) {
      setAdminData(user);
    }
  }, [user]);

  return (
    <div className={styles["layout"]} onClick={() => setShow(false)}>
      <Sidebar dashboard={dashboardPath} />

      <div
        className={styles["layout-body"]}
        //  style={{height:height}}
        id="layout"
      >
        <Header
          signOut={signOut}
          dashboard={dashboardPath}
          headerPath={headerPath}
          backArrow={backArrow}
          isAdmin={isAdmin}
          onEditAccount={onEditAccount}
          onUpdatePassword={onUpdatePassword}
        />
        {children}
      </div>
      <EditAccountModal
        isAdmin={isAdmin}
        show={editAccountModal}
        onHide={onEditAccount}
        register={accountRegister}
        handleSubmit={accountHandleSubmit}
        onSubmit={onSubmitAccountForm}
        formError={accountErrors}
        reset={resetAccountForm}
        loading={loading}
      />
      {/* <UpdatePasswordModal
        show={updatePasswordModal}
        onSubmit={onSubmitPasswordChangeForm}
        onHide={onUpdatePassword}
        loading={loading}
      /> */}

      <div
        style={
          {
            // height:"200px"
          }
        }
      ></div>
    </div>
  );
}

export default withAuthenticator(AdminLayout, {
  hideSignUp: true,
});

export const ORDER_STATUS = {
  en: {
    WAIT_FOR_PAYMENT: 'Waiting for Payment',
    WAIT_FOR_SHIPPING: 'Waiting for Delivery',
    SHIPPING: 'Shipping',
    DELIVERY_COMPLETE: 'Delivery Complete',
    ORDER_CANCEL: 'Order Canceled',
    ORDER_ISSUED: 'Order Issued',
  },
  ja: {
    WAIT_FOR_PAYMENT: '支払い待ち',
    WAIT_FOR_SHIPPING: '発送待ち',
    SHIPPING: '配送中',
    DELIVERY_COMPLETE: '納品完了',
    ORDER_CANCEL: 'キャンセル',
    ORDER_ISSUED: '不具合',
  },
};

export const PAYMENT_TYPS = {
  en: {
    CASH_ON_DELIVERY: 'COD',
    ONLINE: '',
    ONLINE_PLACE_HOLDER: 'online',
  },
  ja: {
    CASH_ON_DELIVERY: '代引き',
    ONLINE: '',
    ONLINE_PLACE_HOLDER: 'オンライン決済',
  },
};

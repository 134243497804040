import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocale } from "../../../locales";
import {
  btnGrey,
  colorBlue,
  colorPrimary,
  colorWhite,
} from "../../../styles/colors";
import { Button, Text } from "../../atoms";

import InputGroup from "../../molecules/InputGroup";
import type { AddBikeFormProps } from "./@types";
import styles from "./AddBikeForm.module.scss";
import Loader from "@/components/atoms/Loader";

const AddBikeForm = ({
  onSubmit,
  onHide = () => {},
  loading,
}: AddBikeFormProps) => {
  const {
    localeButtons,
    localeLabels,
    localeTitles,
    localePlaceholders,
    localeErrors,
  } = useLocale();
  const [confirm, setConfirm] = useState(false);
  const [data, setData] = useState({
    name: "",
    maker: "",
    emission: "",
  });
  const {
    register,
    handleSubmit: handleSubmitForm,
    formState: { errors },
  } = useForm();
  const onSubmitForm: SubmitHandler<any> = (data) => {
    setConfirm(true);
    setData(data);
  };
  const onSubmitConfirm = () => {
    if (!confirm) return;
    onSubmit(data);
  };

  type FormField = {
    validation: object;
    label: string;
    placeholder: string;
    type: "email" | "password" | "text" | "number";
    name: string;
  };
  const formFields: FormField[] = [
    {
      validation: {
        required: localeErrors.ERROR_REQUIRE_NAME,
        minlength: 2,
      },
      name: "name",
      label: localeLabels.LABEL_BIKE_NAME,
      placeholder: localePlaceholders.PLACEHOLDER_ENTER_BIKE_NAME,
      type: "text",
    },
    {
      validation: {
        required: localeErrors.ERROR_REQUIRE_Maker,
        minlength: 2,
      },
      name: "maker",
      label: localeLabels.LABEL_MAKER,
      placeholder: localePlaceholders.PLACEHOLDER_ENTER_MANUFACTURER,
      type: "text",
    },
    {
      validation: {
        required: localeErrors.ERROR_REQUIRE_EMISSION,
        minlength: 2,
      },
      name: "emission",
      label: localeLabels.LABEL_EMISSION,
      placeholder: localePlaceholders.PLACEHOLDER_ENTER_EMISSIONS,
      type: "number",
    },
  ];

  return (
    <div className={styles["main"]}>
      <form onSubmit={handleSubmitForm(onSubmitForm)}>
        {!confirm ? (
          <>
            <Text
              textStyle={{
                fontWeight: "700",
                fontSize: "24px",
                marginBottom: "0.75rem",
                color: colorPrimary,
                textAlign: "center",
                // width: "max-content",
              }}
            >
              {localeTitles.TITLE_ADD_BIKE}
            </Text>
            {formFields.map((field, i) => (
              <InputGroup
                register={register}
                placeholder={field.placeholder}
                type={field.type}
                label={field.label}
                name={field.name}
                error={errors[field.name]}
                validation={field.validation}
                weight="600"
                fontFamily="Montserrat-SemiBold"
              />
            ))}
          </>
        ) : (
          <>
            <Text
              size={"l"}
              textStyle={{ textAlign: "center", fontWeight: "bold" }}
            >
              {localeTitles.TITLE_ADD_BIKE}
            </Text>
            <div>
              <div className="mt-6 mb-2 ">
                <Text size={"xxs"} className="font-bold">
                  {localeLabels.LABEL_BIKE_NAME}
                </Text>
                <Text size={"xxs"} className="font-bold">
                  {data.name}
                </Text>
              </div>
              <div className="my-2">
                <Text size={"xxs"} className="font-bold2">
                  {localeLabels.LABEL_MAKER}
                </Text>
                <Text size={"xxs"} className="font-bold w-8/12">
                  {data.maker}
                </Text>
              </div>
              <div className="my-2">
                <Text size={"xxs"} className="font-bold2">
                  {localeLabels.LABEL_EMISSION}
                </Text>
                <Text size={"xxs"} className="font-bold w-8/12">
                  {data.emission}
                </Text>
              </div>
            </div>
          </>
        )}
        <div className={styles["main-btn"]}>
          <Button
            type={!confirm ? "submit" : "button"}
            color={colorWhite}
            backgroundColor={colorPrimary}
            border={colorWhite}
            padding="8px"
            margin="1rem 0"
            width="100%"
            height=""
            fontWeight="700"
            buttonStyle={{}}
            loading={loading}
            onClick={onSubmitConfirm}
          >
            {!confirm ? (
              localeButtons.BUTTON_NEXT
            ) : !loading ? (
              localeButtons.BUTTON_CONFIRM
            ) : (
              <Loader color={colorWhite} text={localeButtons.BUTTON_LOADING} />
            )}
          </Button>
          <Button
            type="submit"
            color={colorWhite}
            backgroundColor={btnGrey}
            border={colorWhite}
            padding="8px"
            margin="1rem 0"
            width="100%"
            height=""
            fontWeight="700"
            buttonStyle={{}}
            onClick={onHide}
          >
            {localeButtons.BUTTON_CANCEL}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddBikeForm;

export const ERRORS = {
  en: {
    ERROR_INVALID_PW:
      "Password must be at least 8 characters long and must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
    ERROR_PW_NOT_MATCH: "Password and Confirm Password do not match",
    ERROR_INVALID_PW_WEAK:
      "Password contain alphabate and number and length must be 6 to 12",
    ERROR_REQUIRE_PW: "Password is required",
    ERROR_REQUIRE_EMAIL: "Email is required",
    ERROR_REQUIRE_NAME: "Name is required",
    ERROR_REQUIRE_Maker: "Maker is required",
    ERROR_REQUIRE_EMISSION: "Emission is required",
    ERROR_INVALID_EMAIL: "Invalid Email",
    ERROR_INVALID_PROVINCE: "Invalid Province",
    ERROR_PLEASE_TYPE: "Please type here...",
    ERROR_INVALID_ADMIN_NAME: "Admin name length must be more than 3",
    ERROR_INVALID_ZIPCODE: "郵便番号が無効です",
    ERROR_INVALID_ADDRESS: "住所が必要です",
    ERROR_INVALID_PHONE: "電話番号が必要です",
    ERROR_LOGIN_FAIL: "Login failed",
    ERROR_SESSION_EXPIRE: "Session expired",
    ERROR_NETWORK: "Network error occured",
    ERROR_UNKNOWN: "Something went wrong",
    ERROR_INVALID_ORDER: "発注数を入力してください",
    ERROR_WRONG_CRED: "Your credentials do not match",
    ERROR_NAME_ALREADY_ADDED: "Name already added",
  },
  ja: {
    ERROR_REQUIRE_NAME: "Name is required",
    ERROR_REQUIRE_Maker: "Maker is required",
    ERROR_REQUIRE_EMISSION: "Emission is required",
    ERROR_PLEASE_TYPE: "Please type here...",
    ERROR_INVALID_PROVINCE: "Invalid Province",
    ERROR_INVALID_PW:
      "パスワードは少なくとも8文字以上で、少なくとも1つの大文字、1つの小文字、1つの数字、1つの特殊文字を含む必要があります。",
    ERROR_PW_NOT_MATCH: "確認用のパスワードが一致してません",
    ERROR_INVALID_PW_WEAK:
      "パスワードは英数字で6文字以上12文字以下で入力してください。",
    ERROR_REQUIRE_PW: "パスワードが必要です",
    ERROR_REQUIRE_EMAIL: "メールアドレスが必要です",
    ERROR_INVALID_EMAIL: "無効なメールアドレス",
    ERROR_INVALID_ADMIN_NAME: "管理者名は3文字以上で入力してください",

    ERROR_INVALID_ZIPCODE: "郵便番号が無効です。7文字で入力してください",
    ERROR_INVALID_ADDRESS: "住所が必要です",
    ERROR_INVALID_PHONE: "電話番号が必要です",
    ERROR_LOGIN_FAIL: "ログイン失敗",
    ERROR_SESSION_EXPIRE: "セッションが切れました",
    ERROR_NETWORK: "ネットワークエラーが起きました",
    ERROR_UNKNOWN: "不明なエラーが起きました",
    ERROR_INVALID_ORDER: "発注数を入力してください",

    // added nex
    ERROR_WRONG_CRED: "メールアドレスまたはパスワードが違います",
    ERROR_NAME_ALREADY_ADDED: "Name already added",
  },
};

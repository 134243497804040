const awsExports = {
  Auth: {
    identityPoolId: "ap-northeast-1_RjGq5jJVt",

    userPoolId: "ap-northeast-1_RjGq5jJVt",

    userPoolWebClientId: "7nvvjco6ho3s8pao2emvg2mrgj",
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      // domain: "app.motofellow.com",
      domain:
        process.env.NODE_ENV === "development"
          ? `localhost`
          : `app.motofellow.com`,

      // OPTIONAL - Cookie path
      path: "/",
      // OPTIONAL - Cookie expiration in days
      expires: 30,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      // sameSite: "strict" | "lax",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true,
    },
  },
};

export default awsExports;

import React, { useEffect } from "react";
import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "../components/templates/Layout";
import { useLocale } from "../locales";
import { AdminAuthRoutes } from "./AdminAuthRoute";

import Users from "@/pages/UserPages/Users";
import BikeList from "@/pages/BikeList";
import UserDetails from "@/pages/UserPages/UserDetails";
import { LoginPage } from "@/pages/auth/Login";
import { ForgotPasswordPage } from "@/pages/auth/forgotPassword";
import ChangePasswordPage from "@/pages/auth/ChangePasswordPage";
import CategoryChart from "@/pages/CategoryChart";
import BikeDetailsPage from "@/pages/BikeDetailsPage";

const AdminRoutes = () => {
  const { localeButtons, localeLinks } = useLocale();
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Layout
              headerPath={[localeLinks.LINK_LIST_OF_USERS]}
              isProtected={false}
              isAdmin={true}
            >
              <Users />
            </Layout>
          }
        />
        <Route
          path="/users"
          element={
            <Layout
              headerPath={[localeLinks.LINK_LIST_OF_USERS]}
              isProtected={false}
              isAdmin={true}
            >
              <Users />
            </Layout>
          }
        />
        <Route
          path="/bikes"
          element={
            <Layout
              headerPath={[localeLinks.LINK_LIST_OF_BIKES]}
              isProtected={false}
              isAdmin={false}
            >
              <BikeList />
            </Layout>
          }
        />
        <Route
          path="/bike/details"
          element={
            <Layout
              headerPath={[
                localeLinks.LINK_LIST_OF_BIKES,
                localeLinks.LINK_BIKE_DETAILS,
              ]}
              isProtected={false}
              isAdmin={false}
            >
              <BikeDetailsPage />
            </Layout>
          }
        />
        <Route
          path="/users/details"
          element={
            <Layout
              headerPath={[
                localeLinks.LINK_LIST_OF_USERS,
                localeLinks.LINK_USER_DETAILS,
              ]}
              isProtected={false}
              isAdmin={false}
            >
              <UserDetails />
            </Layout>
          }
        />
        <Route
          path="/categories"
          element={
            <Layout
              headerPath={[localeLinks.LINK_CHARTS_SCREEN]}
              isProtected={false}
              isAdmin={false}
            >
              <CategoryChart />
            </Layout>
          }
        />

        <Route path="/delete/account" element={<LoginPage />} />
        {/* <Route
          path="/forgot"
          element={
            <AdminAuthRoutes>
              <ForgotPasswordPage />
            </AdminAuthRoutes>
          }
        />
        <Route path="" element={<Navigate to="/signin" />} />
        <Route path="/" element={<Navigate to="/signin" />} />

        <Route
          path="/resetPassword/:token"
          element={
            <AdminAuthRoutes>
              <ChangePasswordPage />
            </AdminAuthRoutes>
          }
        />  */}

        {/* 404 */}

        {/* // <Route path='*' element={<Page404 />} />   */}
      </Routes>
    </>
  );
};

export default AdminRoutes;

import { useWidth } from "@/utils/responsiveHook";
import { loadingTableProps } from "./@types";
import styles from "./LoadingTable.module.scss";

function LoadingTable({ tableType = "LIST_OF_USERS" }: loadingTableProps) {
  const { width } = useWidth();

  switch (tableType) {
    case "LIST_OF_USERS":
      return (
        <>
          {[0, 1].map((iterator, index) => {
            return width <= 767 ? (
              <tr
                key={index}
                className=" text-md w-full border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold animated-background"
              >
                <td className="py-3 md:py-4 px-5"></td>
                <td className="py-3 md:py-4 px-5"></td>

                <td className="py-3 md:py-4 px-5"></td>

                <td className="py-3 md:py-4 px-5"></td>
              </tr>
            ) : (
              <tr
                key={index}
                className=" text-md w-full border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold animated-background"
              >
                <td className="py-3 md:py-4 px-5"></td>
                <td className="py-3 md:py-4 px-5"></td>

                <td className="py-3 md:py-4 px-5"></td>

                <td className="py-3 md:py-4 px-5"></td>
                <td className="py-3 md:py-4 px-5"></td>

                <td className="py-3 md:py-4 px-5"></td>
                <td className="py-3 md:py-4 px-5"></td>
                <td className="py-3 md:py-4 px-5"></td>
              </tr>
            );
          })}
        </>
      );

    case "LIST_OF_BIKES":
      return (
        <>
          {[0, 1].map((iterator, index) => {
            return width <= 767 ? (
              <tr
                key={index}
                className=" text-md w-full border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold animated-background"
              >
                <td className="py-3 md:py-4 px-5"></td>
                <td className="py-3 md:py-4 px-5"></td>

                <td className="py-3 md:py-4 px-5"></td>
              </tr>
            ) : (
              <tr
                key={index}
                className=" text-md w-full border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold animated-background"
              >
                <td className="py-3 md:py-4 px-5"></td>
                <td className="py-3 md:py-4 px-5"></td>

                <td className="py-3 md:py-4 px-5"></td>

                <td className="py-3 md:py-4 px-5"></td>
                <td className="py-3 md:py-4 px-5"></td>
              </tr>
            );
          })}
        </>
      );

    default:
      return null;
  }
}

export default LoadingTable;

import React, { useEffect, useState } from "react";
import type { ChartComponentProps } from "./@types";
import styles from "./ChartComponent.module.scss";
import { useWidth } from "@/utils/responsiveHook";
import Chart from "@/components/organisms/Chart";
import MonthlyOrders from "@/components/organisms/Monthly Orders";
import { Text } from "@/components/atoms";
import { useLocale } from "@/locales";

const ChartComponent = ({
  data,
  heading,
  loading,
  type,
  options,
  getMonthlyData = (date, type) => {},
}: ChartComponentProps) => {
  const { width } = useWidth();
  const { localeTitles } = useLocale();
  return (
    <div className="mt-4  md:mt-10 flex flex-col justify-center bg-white lg:bg-custom-foreground text-base  md:w-[840px] rounded-lg py-8 mb-6 px-[25px] mx-auto ">
      <div className={styles["user-detail"]}>
        <Text size={"l"} weight="700" textStyle={{ textAlign: "center" }}>
          {heading == "POST" && localeTitles.TITLE_TOTAL_FEEDS}
          {heading == "BLOGS" && localeTitles.TITLE_MAINTENANCE_BLOG}
          {heading == "THREADS" && localeTitles.TITLE_TOTAL_TALKROOM}
          {heading == "TOURING" && localeTitles.TITLE_TOTAL_TOURINGS}
        </Text>
        <MonthlyOrders
          getMonthlyData={getMonthlyData}
          monthlyOrders={22}
          heading={heading}
        />
        <Chart loading={loading} data={data} type={type} options={options} />
      </div>
    </div>
  );
};

export default ChartComponent;

import LoginTemplate from "@/components/templates/Login";

import { useLogin } from "./hook";
import styles from  "./login.module.scss"

export const LoginPage = () => {
  const { loading, register, handleSubmit, errors,onSubmit,accestoken,handleDeleteAccount } = useLogin();



  if(accestoken)
  {
    return(

<div className={styles['container']}>

      <h1>Successfully Login</h1>

      {/* a delete button which will open dialog alert and will wanrs user to confirm */}
      <button
      
      className={
        styles["delet-Account-btn"]
      }
      onClick={handleDeleteAccount}>Delete Account</button>

    </div>)
  }

  return (
    <LoginTemplate
      loading={loading}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      formError={errors}
      PathToForgotPassword="/forgot"
    />
  );
};

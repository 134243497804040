import { useEffect, useRef, useState } from "react";
import { useLocale } from "../../../locales";
import Text from "@/components/atoms/Text";

import type { BikeDetailsProps } from "./@types";
import styles from "./BikeDetails.module.scss";
import { useWidth } from "@/utils/responsiveHook";
import Loader from "@/components/molecules/Loading";
import Image from "@/components/atoms/Image";
import frame from "../../../assets/images/Frame.png";

const BikeDetails = ({ details, loading }: BikeDetailsProps) => {
  const { localeLabels } = useLocale();
  const ref = useRef<any>(null);
  const { width } = useWidth();

  // console.log("userBikes", details);
  return (
    <div ref={ref} className="flex items-center space-x-6 my-4">
      {loading && (
        <div className="flex justify-center items-center h-full w-full">
          <Loader />
        </div>
      )}

      <div className=" justify-center flex">
        <Image
          imagePath={
            details?._doc?.bikeImage ? details?._doc?.bikeImage : frame
          }
          imageStyle={{
            width: "120px",
            height: "120px",
            objectFit: "contain",
            borderRadius: "8px",
          }}
        />
      </div>

      <div className="flex flex-col space-y-2 font-black">
        <Text size={"s"} weight="700">
          {details?.maker}
        </Text>
        <Text size={"s"} weight="700">
          {details?.name}
        </Text>
        <Text size={"s"} weight="700">
          {details?.emission}
        </Text>
      </div>
    </div>
  );
};

export default BikeDetails;

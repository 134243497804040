import { useState } from "react";
import { useLocale } from "../../../locales";
import { btnGrey, colorBlack, colorWhite } from "../../../styles/colors";
import { Button, Text } from "../../atoms";
import Modal from "../../molecules/Modal";
import type { deactivateModalProps } from "./@types";
import styles from "./DeactivateModal.module.scss";
import Loader from "@/components/atoms/Loader";

const DeactivateModal = ({
  show,
  onHide = () => {},
  onConfirm = () => {},
  name,
  title,
  email,
  loading,
  buttonTitle,
  status,
}: deactivateModalProps) => {
  const { localeButtons, localeLabels, localeTitles } = useLocale();
  return (
    <div className={styles["auth-form"]}>
      <Modal show={show}>
        <div className={styles["main"]}>
          <Text size={"l"} weight="700" textStyle={{ textAlign: "center" }}>
            {title}
          </Text>
          <div>
            <div className="my-2">
              <Text size={"xxs"} className="font-bold">
                {localeLabels.LABEL_NICKNAME}
              </Text>
              <Text size={"xxs"} className="font-bold">
                {name}
              </Text>
            </div>
          </div>
          <div className={styles["main-btn"]}>
            <Button
              color={colorWhite}
              backgroundColor={colorBlack}
              border={colorWhite}
              padding="8px"
              margin="1rem 0"
              width="100%"
              height=""
              fontWeight="700"
              disabled={loading}
              onClick={() => {
                onConfirm();
              }}
            >
              {!loading ? (
                buttonTitle
              ) : (
                <Loader text={buttonTitle} color="#fff" />
              )}
            </Button>
            <Button
              disabled={loading}
              type="submit"
              color={colorWhite}
              backgroundColor={btnGrey}
              border={colorWhite}
              padding="8px"
              margin="1rem 0"
              width="100%"
              height=""
              fontWeight="700"
              onClick={() => {
                onHide("", null);
              }}
            >
              {localeButtons.BUTTON_CANCEL}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeactivateModal;

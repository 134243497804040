import React, { useEffect, useState } from "react";

import type { UsersProps } from "./@types";
import styles from "./Users.module.scss";
import { useUsersList } from "./hook";
import { useWidth } from "@/utils/responsiveHook";
import CommonTable from "@/components/templates/CommonTable";
import { useLocale } from "@/locales";
import SideStats from "@/components/molecules/Side Stats";

const Users = ({}: UsersProps) => {
  const {
    allUsers,
    usersFetchLoading,
    navigateToUserDetails,
    togleShowModalFilter,
    showFilter,
    onSubmit,
  } = useUsersList();
  const { width } = useWidth();
  const { localeLinks } = useLocale();
  console.log("allUsers", allUsers);

  return (
    <div className="mt-4  md:mt-10">
      <div className="justify-between mx-11 my-3 flex-row">
        <SideStats users={allUsers?.length} />
      </div>

      <CommonTable
        title={localeLinks.LINK_LIST_OF_USERS}
        tableType="LIST_OF_USERS"
        loading={usersFetchLoading}
        searchbtnShow={true}
        data={allUsers || []}
        tableFooter={false}
        openSearchModal={togleShowModalFilter}
        onClickClearFilter={togleShowModalFilter}
        showFilter={showFilter}
        onSubmit={onSubmit}
        navigateTo={navigateToUserDetails}
      />
    </div>
  );
};

export default Users;

import { useState } from "react";

import Modal from "../../molecules/Modal";
import AddBikeForm from "../AddBikeForm";
import type { AddBikeModalProps } from "./@types";
import styles from "./AddBikeModal.module.scss";

const AddBikeModal = ({
  show,
  onHide,
  onSubmit,
  addBikeLoading,
}: AddBikeModalProps) => {
  return (
    <div className={styles["auth-form"]}>
      <Modal show={show}>
        <AddBikeForm
          onSubmit={onSubmit}
          onHide={onHide}
          loading={addBikeLoading}
        />
      </Modal>
    </div>
  );
};

export default AddBikeModal;

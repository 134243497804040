import { useState } from "react";
import { useLocale } from "../../../locales";
import { colorBlue, colorPrimary, colorWhite } from "../../../styles/colors";
import { Button } from "../../atoms";
import RedirectText from "../../atoms/Redirect Text/RedirectText";
// import CheckboxGroup from "../../molecules/CheckboxGroup";
import InputGroup from "../../molecules/InputGroup";
import type { FormField, LoginFormProps } from "./@types";
import styles from "./LoginForm.module.scss";
import { REGEXP_PASSWORD } from "@/constants/regexp";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoginInput } from "@/types/Gloabal";
import { SubmitHandler, useForm } from "react-hook-form";

const patren = REGEXP_PASSWORD;
const LoginForm = ({
  register,
  handleSubmit,
  onSubmit,
  loading = false,
  formError,
  passwordPattren = patren,
  PathToForgotPassword = "/forgot",
}: LoginFormProps) => {
  const {
    localeButtons,
    localeErrors,
    localeLabels,
    localeLinks,
    localePlaceholders,
  } = useLocale();

  const formFields: FormField[] = [
    {
      // validation: {
      //   // required: localeErrors.ERROR_INVALID_EMAIL,
      // },
      name: "email",
      label: localeLabels.LABEL_EMAIL,
      placeholder: localePlaceholders.PLACEHOLDER_EMAIL,
      type: "email",
    },
    {
      // validation: {
      //   required: localeErrors.ERROR_INVALID_PW_WEAK,
      //   pattern: passwordPattren,
      // },
      name: "password",
      label: localeLabels.LABEL_PW,
      placeholder: localePlaceholders.PLACEHOLDER_PW,
      type: "password",
      // register: VALIDATION_PASSWORD,
    },
  ];

  // const validationSchema = yup.object().shape({
  //   email: yup
  //     .string()
  //     .email(localeErrors.ERROR_INVALID_EMAIL)
  //     .required(localeErrors.ERROR_INVALID_EMAIL),
  //   password: yup
  //     .string()
  //     .matches(
  //       /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/,
  //       localeErrors.ERROR_INVALID_PW
  //     )
  //     .required(localeErrors.ERROR_INVALID_PW),
  // });
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm<LoginInput>({
  //   resolver: yupResolver(validationSchema),
  // });

  // const submit: SubmitHandler<LoginInput> = (data) => onSubmit(data);

  return (
    <div className={styles["auth-form"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <InputGroup
          register={register("email")}
          placeholder={localePlaceholders.PLACEHOLDER_EMAIL}
          type={"email"}
          label={localeLabels.LABEL_EMAIL}
          name={"email"}
          weight="600"
          error={errors.email}
        />

        <InputGroup
          register={register("password")}
          placeholder={localePlaceholders.PLACEHOLDER_PW}
          type={"password"}
          label={localeLabels.LABEL_PW}
          name={"password"}
          weight="600"
          error={errors.password}
        /> */}
        {formFields.map((field, i) => (
          <InputGroup
            placeholder={field.placeholder}
            type={field.type}
            label={field.label}
            name={field.name}
            error={formError?.[field.name]}
            validation={field.validation}
            weight="600"
            register={register}
          />
        ))}
        {/* <div className={styles["auth-bottom"]}>
          <RedirectText
            text={localeLinks.LINK_FORGOT_PW}
            url={PathToForgotPassword}
            textStyle={{
              fontSize: "18px",
              fontWeight: "bold",
              color: colorBlue,
            }}
            color={colorBlue}
          />
        </div> */}
        <Button
          type="submit"
          color={colorWhite}
          backgroundColor={colorPrimary}
          border={colorWhite}
          padding="8px"
          margin="30px 0 20px 0"
          width="100%"
          height=""
          fontWeight="700"
          buttonStyle={{}}
          loading={loading}
        >
          {localeButtons.BUTTON_LOGIN}
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;

export const TITLES = {
  en: {
    TITLE_BRAND: "Motorbike",
    TITLE_SERVICE: "宇治巨椋園",
    TITLE_LOGIN_ADMIN: "Login for Admin",

    TITLE_PW_RESET: "Reset Password",
    TITLE_SENT_PW_RESET: "Email is sent",
    TITLE_CHANGE_PW: "Change Password",
    TITLE_CREATE_ORDER: "Create an Order",

    TITLE_FILTER: "Filter",
    TITLE_ORDER_STATUS_DELIVERED: "Change order status to delivered?",
    TITLE_ORDER_STATUS_SHIPPED: "Change order status to shipped?",
    TITLE_ORDER_STATUS_ISSUED: "Change order status to Issued order?",
    TITLE_ORDER_STATUS_CANCEL: "Change order status to cancel?",
    TITLE_YEAR_MONTH: "{YYYY}/{MM}",
    TITLE_LOGOUT: "Logout",
    TITLE_LOGIN: "Login",
    TITLE_NO_DATA: "No Data Found",
    TITLE_STATUS_CHANGE: "Change Status",
    TITLE_EDIT_ACCOUNT: "Edit Account",
    TITLE_UPDATE_PW: "Update Password",
    TITLE_REGISTRATION: "Registration Form",
    TITLE_TOTAL_FEEDS: "Total feeds by category for the month",
    TITLE_MAINTENANCE_BLOG: "Maintenance blog by category for the month",
    TITLE_TOTAL_TALKROOM: "Total talkroom by category for the month",
    TITLE_TOTAL_TOURINGS: "Total tourings for the month",
    TITLE_ADD_BIKE: "Add bike",
    TITLE_EDIT_BIKE: "Edit bike",
    TITLE_DEACTIVATE_ACCOUNT: "Deactivate this account",
    TITLE_ALREADY_INACTIVE: "User already inactive",
    TITLE_DELETE_BIKE: "Delete Bike",
  },
  ja: {
    TITLE_DELETE_BIKE: "このバイクを削除しますか？",
    TITLE_EDIT_BIKE: "バイク情報の編集",

    TITLE_BRAND: "Motorbike",
    TITLE_ALREADY_INACTIVE: "User already inactive",
    TITLE_DEACTIVATE_ACCOUNT: "利用停止にする",
    TITLE_ADD_BIKE: "バイクの追加登録",
    TITLE_TOTAL_TALKROOM: "合計トークルーム数",
    TITLE_TOTAL_TOURINGS: "合計ツーリング数",
    TITLE_MAINTENANCE_BLOG: "合計整備日記数",
    TITLE_TOTAL_FEEDS: "合計投稿数",
    TITLE_REGISTRATION: "登録フォーム",
    TITLE_UPDATE_PW: "Update Password",
    TITLE_EDIT_ACCOUNT: "アカウントを編集",
    TITLE_STATUS_CHANGE: "ステータス変更",
    TITLE_NO_DATA: "データが見つかりません",
    TITLE_LOGIN: "ログイン",
    TITLE_LOGOUT: "Logout",
    TITLE_SERVICE: "宇治巨椋園",
    TITLE_LOGIN_ADMIN: "管理者用ログイン",

    TITLE_PW_RESET: "パスワードリセット",
    TITLE_SENT_PW_RESET: "メールを送信しました",
    TITLE_CHANGE_PW: "パスワードの変更",
    TITLE_CREATE_ORDER: "発注する",
    TITLE_FILTER: "絞り込み検索",
    TITLE_ORDER_STATUS_DELIVERED: "この発注を納品完了にしますか？",
    TITLE_ORDER_STATUS_SHIPPED: "この発注を配送中にしますか？",
    TITLE_ORDER_STATUS_ISSUED: "この発注を不具合にしますか？",
    TITLE_ORDER_STATUS_CANCEL: "この発注をキャンセルにしますか？",
    TITLE_YEAR_MONTH: "{YYYY}年{MM}月",
  },
};

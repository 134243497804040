export const BUTTONS = {
  en: {
    BUTTON_LOGIN: "Login",
    BUTTON_LOGOUT: "Logout",
    BUTTON_SEND_EMAIL: "Send Email",
    BUTTON_TO_LOGIN: "Back to login page",
    BUTTON_CHANGE_PW: "Change Password",
    BUTTON_PREV_MONTH: "Prev Month",
    BUTTON_NEXT_MONTH: "Next Month",
    BUTTON_SHIPPED: "Shipped",
    BUTTON_DELIVERED: "Delivered",
    BUTTON_CANCEL: "Cancel",
    BUTTON_ORDER_ISSUED: "Issue Occured",
    BUTTON_DETAIL: "Detail",
    BUTTON_ORDER: "Create an Order",
    BUTTON_NEXT: "Next",
    BUTTON_CONFIRM_ORDER: "Confirm",
    BUTTON_CONFIRM_DEACTIVATE: "Deactivate",
    BUTTON_CONFIRM_ACTIVATE: "Activate",
    BUTTON_APPLY_FILTER: "Apply Filter",
    BUTTON_CLEAR_FILTER: "Clear Filter",
    BUTTON_CONFIRM: "Confirm",
    BUTTON_IN_TRANSIT: "In transit",
    BUTTON_DELIVERY_COMPLETE: "Delivery completed",
    BUTTON_PREVIOUS: "Previous",
    BUTTON_REGISTER: "Register",
    BUTTON_LOADING: "Loading",
    BUTTON_CLOSE: "Close",
    BUTTON_SEARCH_ZIPCODE: "Search Address",
    BUTTON_BACK: "Back",
    BUTTON_ORDER_CANCEL: "Cancel",
    BUTTON_DEACTIVATE_ACCOUNT: "Deactivate this account",
    BUTTON_ACTIVATE_ACCOUNT: "Activate this account",
  },
  ja: {
    BUTTON_ACTIVATE_ACCOUNT: " 利用再開させる",
    BUTTON_DEACTIVATE_ACCOUNT: "利用停止にする",
    BUTTON_BACK: "戻る",
    BUTTON_SEARCH_ZIPCODE: "住所を検索する",
    BUTTON_REGISTER: "登録する",
    BUTTON_PREVIOUS: "前へ",
    BUTTON_DELIVERY_COMPLETE: "納品完了",
    BUTTON_IN_TRANSIT: "配送中",
    BUTTON_LOGOUT: "ログアウト",
    BUTTON_LOGIN: "ログイン",
    BUTTON_SEND_EMAIL: "メールを送信",
    BUTTON_TO_LOGIN: "ログインページへ",
    BUTTON_CHANGE_PW: "パスワード変更",
    BUTTON_PREV_MONTH: "前の月",
    BUTTON_NEXT_MONTH: "次の月",
    BUTTON_SHIPPED: "配送中へ",
    BUTTON_DELIVERED: "納品完了へ",
    BUTTON_CANCEL: "キャンセル",
    BUTTON_ORDER_ISSUED: "不具合発生へ",
    BUTTON_DETAIL: "詳細",
    BUTTON_ORDER: "発注する",
    BUTTON_NEXT: "次へ",
    BUTTON_CONFIRM_ORDER: "発注する",
    BUTTON_CONFIRM_DEACTIVATE: "利用不可にする",
    BUTTON_CONFIRM_ACTIVATE: "利用可能にする",
    BUTTON_APPLY_FILTER: "絞り込む",
    BUTTON_CLEAR_FILTER: "絞り込みをクリア",
    BUTTON_CONFIRM: "確認する",
    BUTTON_LOADING: "読み込み中",
    BUTTON_CLOSE: "閉じる",
    BUTTON_ORDER_CANCEL: "キャンセルへ",
  },
};

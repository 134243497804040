import { loadavg } from 'os';
import Modal from '../../molecules/Modal';
import FilterForm from '../Filter Form';
import type { filterModalProps } from './@types';
import styles from './FilterModal.module.scss';

const FilterModal = ({
  show,
  onClickClearFilter = () => {},
  onSubmit,
  loading,
  filterType = 'users',
}: filterModalProps) => {
  return (
    <div className={styles['auth-form']}>
      <Modal show={show}>
        <FilterForm
          type={filterType}
          onSubmit={onSubmit}
          loading={loading}
          onClickClearFilter={onClickClearFilter}
        />
      </Modal>
    </div>
  );
};

export default FilterModal;

import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import Modal from "../../molecules/Modal";
import AdminEditAccountForm from "../Admin Edit Account Form";
import type { editAccountModalProps } from "./@types";
import styles from "./EditAccountModal.module.scss";
import { useLocale } from "@/locales";

const EditAccountModal = ({
  show,
  onHide,
  isAdmin,
  onSubmit,
  loading,
}: editAccountModalProps) => {
  const { localeButtons, localeErrors, localeLabels, localePlaceholders } =
    useLocale();

  const formFields: any = [
    {
      validation: {
        required: localeErrors.ERROR_INVALID_ADMIN_NAME,
        minLength: 3,
      },
      name: "name",
      label: localeLabels.LABEL_ADMIN_NAME,
      placeholder: "localePlaceholders.PLACEHOLDER_ENTER_VENDOR_NAME",
      type: "text",
    },
    {
      validation: {
        required: localeErrors.ERROR_INVALID_EMAIL,
      },
      name: "email",
      label: localeLabels.LABEL_EMAIL,
      placeholder: localePlaceholders.PLACEHOLDER_EMAIL,
      type: "email",
    },
  ];

  return (
    <div className={styles["auth-form"]}>
      <Modal show={show} height={false}>
        <AdminEditAccountForm
          formFields={formFields}
          onSubmit={onSubmit}
          onHide={onHide}
          loading={loading}
        />
      </Modal>
    </div>
  );
};

export default EditAccountModal;

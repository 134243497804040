import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ToastConsumer } from "@/store/ToastProvider";
import { UsersContextConsumer } from "@/store/AuthProvider";
import { useQuery } from "react-query";

import { localeTitles, useLocale } from "@/locales";

export const useUsersDetails = () => {
  const toast = ToastConsumer();
  const navigate = useNavigate();
  const { localeSuccess } = useLocale();
  const { adminData } = UsersContextConsumer();
  const [queryParams] = useSearchParams();
  const [userId, setUserId] = useState(queryParams.get("userId") + "");
  const [show, setShow] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [deActivateLoading, setDeactivateLoading] = useState(false);

  const togelDeactivateModal = () => {
    setShowDeactivateModal(!showDeactivateModal);
  };

  const onModal = () => {
    setShow(!show);
  };

 

  const navigateToUserDetails = (userId: string) => {
    navigate("/users/details?userId=" + userId);
  };


  useEffect(() => {
    if (!userId) {
      alert(queryParams.get("id"));
      setUserId(queryParams.get("id") + "");
    }
  }, [userId]);

  return {
    show,
    onModal,
   
    navigateToUserDetails,
    showDeactivateModal,
    togelDeactivateModal,
    deActivateLoading,
 
  };
};

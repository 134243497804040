import React from "react";
type Props = { white?: boolean; size?: "MOBILE" | "TABLET" | "LARGE" };
function ArrowRightIcon({ white, size }: Props) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M9 18.795l7.439-4.295L9 10.205V7l12.99 7.5L9 22v-3.205z"
      clipRule="evenodd"
    ></path>
  </svg>
  );
}

export default ArrowRightIcon;

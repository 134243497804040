import React, { useEffect, useState } from "react";

import type { UserDetailsProps } from "./@types";
import styles from "./UserDetails.module.scss";
import { useWidth } from "@/utils/responsiveHook";
import Image from "@/components/atoms/Image";
import UserBio from "@/components/organisms/UserBio";
import BikeDetails from "@/components/organisms/BikeDetails";
import { useUsersDetails } from "./hook";
import AddButton from "@/components/molecules/Add Agency Button";
import { useLocale } from "@/locales";
import DeactivateModal from "@/components/organisms/Deactivate Modal";

const UserDetails = ({}: UserDetailsProps) => {
  const {
    loadingUserDeatils,
    userDetails,
    onModal,
    showDeactivateModal,
    togelDeactivateModal,
    deActivateLoading,
    DeActivateUser,
    userBikes,
    loadingUserBikes,
  } = useUsersDetails();
  const { localeTitles, localeButtons } = useLocale();
  console.log("userDetails", userDetails);
  console.log("userBikes", userBikes);

  const { width } = useWidth();
  const details = {
    nickName: "Tester",
    province: "Sindh",
    gender: "Male",
    numOfFollows: "200",
    numOfFollowers: "400",
    lastLogin: "2023.1.1",
  };
  const bikes = [
    {
      brand: "SUZUKI",
      bike: "V-STORM 1050XT",
      emission: "1036cc",
    },
    {
      brand: "SUZUKI",
      bike: "V-STORM 1050XT",
      emission: "1036cc",
    },
    {
      brand: "SUZUKI",
      bike: "V-STORM 1050XT",
      emission: "1036cc",
    },
  ];
  const svg = (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.625 18.375C7.85417 18.6042 8.14583 18.7188 8.5 18.7188C8.85417 18.7188 9.14583 18.6042 9.375 18.375L13 14.75L16.6562 18.4062C16.8854 18.6354 17.1721 18.7446 17.5163 18.7337C17.8596 18.7237 18.1458 18.6042 18.375 18.375C18.6042 18.1458 18.7188 17.8542 18.7188 17.5C18.7188 17.1458 18.6042 16.8542 18.375 16.625L14.75 13L18.4062 9.34375C18.6354 9.11458 18.7446 8.82792 18.7337 8.48375C18.7237 8.14042 18.6042 7.85417 18.375 7.625C18.1458 7.39583 17.8542 7.28125 17.5 7.28125C17.1458 7.28125 16.8542 7.39583 16.625 7.625L13 11.25L9.34375 7.59375C9.11458 7.36458 8.82833 7.255 8.485 7.265C8.14083 7.27583 7.85417 7.39583 7.625 7.625C7.39583 7.85417 7.28125 8.14583 7.28125 8.5C7.28125 8.85417 7.39583 9.14583 7.625 9.375L11.25 13L7.59375 16.6562C7.36458 16.8854 7.25542 17.1717 7.26625 17.515C7.27625 17.8592 7.39583 18.1458 7.625 18.375ZM13 25.5C11.2708 25.5 9.64583 25.1717 8.125 24.515C6.60417 23.8592 5.28125 22.9688 4.15625 21.8438C3.03125 20.7188 2.14083 19.3958 1.485 17.875C0.828333 16.3542 0.5 14.7292 0.5 13C0.5 11.2708 0.828333 9.64583 1.485 8.125C2.14083 6.60417 3.03125 5.28125 4.15625 4.15625C5.28125 3.03125 6.60417 2.14042 8.125 1.48375C9.64583 0.827917 11.2708 0.5 13 0.5C14.7292 0.5 16.3542 0.827917 17.875 1.48375C19.3958 2.14042 20.7188 3.03125 21.8438 4.15625C22.9688 5.28125 23.8592 6.60417 24.515 8.125C25.1717 9.64583 25.5 11.2708 25.5 13C25.5 14.7292 25.1717 16.3542 24.515 17.875C23.8592 19.3958 22.9688 20.7188 21.8438 21.8438C20.7188 22.9688 19.3958 23.8592 17.875 24.515C16.3542 25.1717 14.7292 25.5 13 25.5Z"
        fill="white"
      />
    </svg>
  );

  return (
    <>
      <div className=" mx-11 my-3 flex  justify-end">
        <div className="flex md:flex-row flex-col-reverse md:justify-between  my-4 ">
          <AddButton
            color="GREY"
            text={
              userDetails?.profile?.status === "active"
                ? localeButtons.BUTTON_DEACTIVATE_ACCOUNT
                : localeButtons.BUTTON_ACTIVATE_ACCOUNT
            }
            svg={userDetails?.profile?.status === "active" && svg}
            onClick={togelDeactivateModal}
          />
        </div>
      </div>
      <div className="mt-4  md:mt-10 flex flex-col justify-center  bg-white lg:bg-custom-foreground text-base md:w-[500px] rounded-lg py-8 mb-6 px-[25px] mx-auto ">
        <div className={styles["user-detail"]}>
          <UserBio
            loading={loadingUserDeatils}
            details={userDetails?.profile}
          />
        </div>
        <div className={styles["user-vehicle"]}>
          {userBikes?.userBikes?.map((bike: any, i: number) => (
            <BikeDetails loading={loadingUserBikes} details={bike} />
          ))}
        </div>
        <DeactivateModal
          show={showDeactivateModal}
          buttonTitle={
            userDetails?.profile?.status === "active"
              ? localeButtons.BUTTON_CONFIRM_DEACTIVATE
              : localeButtons.BUTTON_CONFIRM_ACTIVATE
          }
          onHide={togelDeactivateModal}
          onConfirm={DeActivateUser}
          loading={deActivateLoading}
          title={
            userDetails?.profile?.status === "active"
              ? localeTitles.TITLE_DEACTIVATE_ACCOUNT
              : localeButtons.BUTTON_ACTIVATE_ACCOUNT
          }
          name={userDetails?.profile?.nickName}
        />
      </div>
    </>
  );
};

export default UserDetails;

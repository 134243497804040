import { TITLES } from "./TITLES";
import { PLACEHOLDERS } from "./PLACEHOLDERS";
import { LABELS } from "./LABELS";
import { LOGOS } from "./LOGO";
import { BUTTONS } from "./BUTTONS";
import { ERRORS } from "./ERRORS";
import { SUCCESS } from "./SUCCESS";
import { useState } from "react";
import { useLocelContextTypes } from "./@types";
import { DROPDOWNS } from "./DROPDOWNS";
import { language } from "@/constants/localStorage.key";
import { createCtx } from "@/store/utils";
import { LINKS } from "./LINK";
import { ORDER_STATUS, PAYMENT_TYPS } from "./ORDER_STATUS";
import { STATUS } from "./STATUS";

export const lanTest = {
  lang: "ja",
};
export const getLang = () => {
  const langua = localStorage.getItem(language);
  if (langua === "ja") {
    return "ja";
  } else if (langua === "en") {
    return "en";
  } else {
    const browserLanguage =
      navigator.languages.includes("ja") ||
      navigator.languages.includes("ja-JP");
    return browserLanguage ? "ja" : "en";
  }
};
let lang: "en" | "ja" = getLang();

const localeTitles = TITLES[lang];
const localePlaceholders = PLACEHOLDERS[lang];
const localeLabels = LABELS[lang];
const localeLogos = LOGOS[lang];
const localeButtons = BUTTONS[lang];
const localeDropdowns = DROPDOWNS[lang];
const localeErrors = ERRORS[lang];
const localeSuccess = SUCCESS[lang];
const localeLinks = LINKS[lang];
const localeOrderStatus = ORDER_STATUS[lang];
const localeStatus = STATUS[lang];
const localePaymentType = PAYMENT_TYPS[lang];

export {
  localeTitles,
  localePlaceholders,
  localeLabels,
  localeLogos,
  localeButtons,
  localeDropdowns,
  localeErrors,
  localeSuccess,
  localeLinks,
  localeOrderStatus,
  localeStatus,
  localePaymentType,
};

export const [usLocale, Provider] = createCtx<useLocelContextTypes>();

const useAuthCtx = (): useLocelContextTypes => {
  // const location = useLocation();

  let lang: "en" | "ja" = getLang();
  const [texts, setStexts] = useState({
    localeTitles: TITLES[lang],
    localePlaceholders: PLACEHOLDERS[lang],
    localeLabels: LABELS[lang],
    localeLogos: LOGOS[lang],
    localeButtons: BUTTONS[lang],
    localeDropdowns: DROPDOWNS[lang],
    localeErrors: ERRORS[lang],
    localeSuccess: SUCCESS[lang],
    localeLinks: LINKS[lang],
    localeOrderStatus: ORDER_STATUS[lang],
    localeStatus: STATUS[lang],
  });

  const changeLocale = (lang: "en" | "ja") => {
    localStorage.setItem(language, lang);
    lang = getLang();
    setStexts({
      localeTitles: TITLES[lang],
      localePlaceholders: PLACEHOLDERS[lang],
      localeLabels: LABELS[lang],
      localeLogos: LOGOS[lang],
      localeButtons: BUTTONS[lang],
      localeDropdowns: DROPDOWNS[lang],
      localeErrors: ERRORS[lang],
      localeSuccess: SUCCESS[lang],
      localeLinks: LINKS[lang],
      localeOrderStatus: ORDER_STATUS[lang],
      localeStatus: STATUS[lang],
    });
  };

  return {
    ...texts,
    changeLocale,
  };
};

const LocaleProvider = ({ children }: { children: JSX.Element | any }) => {
  const value = useAuthCtx();
  return <Provider value={value}>{children}</Provider>;
};

export const useLocale = () => {
  const locale = usLocale();
  return locale;
};

export default LocaleProvider;

import React, { useEffect, useState } from "react";
import type { BikeListProps } from "./@types";
import styles from "./BikeList.module.scss";
import { useBikeList } from "./hook";
import { useWidth } from "@/utils/responsiveHook";
import AddButton from "@/components/molecules/Add Agency Button";
import { useLocale } from "@/locales";
import CommonTable from "@/components/templates/CommonTable";
import SideStats from "@/components/molecules/Side Stats";
import AddBikeModal from "@/components/organisms/AddBikeModal";
import EditBikeModal from "@/components/organisms/EditBikeModal";

const BikeList = ({}: BikeListProps) => {
  const {
    showConfirmationModal,
    modalType,
    BikeData,
    bikesFetchLoading,
    addBikeLoading,
    onModal,
    show,
    onAddBike,
    togleShowModalFilter,
    showFilter,
    onSubmit,
    navigateToBikeDetails,
    onClickDeleteBike,
    onDeleteBike,
    onModalEdit,
    showEdit,
    bikeData,
    onEditBike,
  } = useBikeList();
  const { width } = useWidth();
  const { localeLinks, localeTitles, localeButtons } = useLocale();

  const svg = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6667 13.3333H13.3333V18.6667H10.6667V13.3333H5.33333V10.6667H10.6667V5.33333H13.3333V10.6667H18.6667M21.3333 0H2.66667C1.18667 0 0 1.18667 0 2.66667V21.3333C0 22.0406 0.280951 22.7189 0.781048 23.219C1.28115 23.719 1.95942 24 2.66667 24H21.3333C22.0406 24 22.7189 23.719 23.219 23.219C23.719 22.7189 24 22.0406 24 21.3333V2.66667C24 1.95942 23.719 1.28115 23.219 0.781048C22.7189 0.280951 22.0406 0 21.3333 0Z"
        fill="white"
      />
    </svg>
  );

  return (
    <div className="space-y-1 mt-2 sm:mt-10 sm:space-y-4">
      <div className="justify-between mx-11 my-3 flex-row">
        <div className="flex md:flex-row flex-col-reverse md:justify-between justify-center my-4">
          <AddButton
            color="BLUE"
            text={localeTitles.TITLE_ADD_BIKE}
            svg={svg}
            onClick={onModal}
          />
          <div className="slef-end max-md:ml-5 max-md:my-5">
            <SideStats bikes={BikeData?.length} />
          </div>
        </div>
      </div>

      <CommonTable
        tableFooter={false}
        title={localeLinks.LINK_LIST_OF_BIKES}
        searchbtnShow={true}
        tableType="LIST_OF_BIKES"
        loading={bikesFetchLoading}
        data={BikeData || []}
        showConfimationModal={showConfirmationModal}
        modalType={modalType}
        openSearchModal={togleShowModalFilter}
        onClickClearFilter={togleShowModalFilter}
        showFilter={showFilter}
        onSubmit={onSubmit}
        navigateTo={navigateToBikeDetails}
        confirmModalTitle={localeTitles.TITLE_DELETE_BIKE}
        onClickCancelBtnModal={onClickDeleteBike}
        onCLickConfirmBtnModal={onDeleteBike}
        onClickEdit={onModalEdit}
      />

      <AddBikeModal
        addBikeLoading={addBikeLoading}
        show={show}
        onHide={onModal}
        onSubmit={onAddBike}
      />
      <EditBikeModal
        addBikeLoading={addBikeLoading}
        show={showEdit}
        onHide={onModalEdit}
        onSubmit={onEditBike}
        bikeData={bikeData}
      />
    </div>
  );
};

export default BikeList;

import React from "react";
import { LoaderProps } from "./@types";
import Text from "../Text";
import Spinner from "../../../assets/Spinner";
import { useLocale } from "../../../locales";
// import LoaderGif from "../../../assets/images/Spin.gif";

const Loader = ({ text }: LoaderProps): JSX.Element => {
  const { localeButtons } = useLocale();
  return (
    <span
      className="animate-pulse"
      style={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner />
      {text}
    </span>
  );
};

export default Loader;

import { loginFormFields } from "@/types/FormFields";
import { addNewBikeForm } from "@/types/Gloabal";
import apiRequest from "@/utils/axios";

export const ApiSignin = async (data:loginFormFields) => {
  try {
    const result = await apiRequest({
      method: "put",
      url: "/users/auth/login",
      data,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ApiDeleteAccount = async (token: any) => {
  console.log("token", token);
  try {
    const result = await apiRequest({
      method: "delete",
      url: "/users/myProfile/delete",
      token,
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

import React from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";

import { colorBlack, colorGray, colorLabel } from "../../../styles/colors";
import { Text } from "../../atoms";
import Input from "../../atoms/Input";
import type { cardProps } from "./@types";
import styles from "./Card.module.scss";
import { useWidth } from "@/utils/responsiveHook";

const Card = ({
  children,
  back,
  cross,
  className = "max-sm:mx-[10px]",
  ChildContainer = "",
  customStyle = "",
  height,
  ...props
}: cardProps) => {
  const {width} = useWidth()
  return (
    <div className={styles["card"]} style={{ minHeight: height?height:width<769?500:600 }}>
      {back ? (
        <div className="absolute cursor-pointer top-2 left-2" {...props}>
          <IoArrowBackOutline size={25} />
        </div>
      ) : null}
      {cross ? (
        <div className="absolute cursor-pointer top-2 right-2" {...props}>
          <IoIosCloseCircle size={20} />
        </div>
      ) : null}
      <div
        className={
          customStyle.length > 0
            ? customStyle
            : styles["card-body"] + ChildContainer
        }
      >
        {children}
      </div>
    </div>
  );
};

export default Card;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastConsumer } from "@/store/ToastProvider";
import { useQuery, useMutation } from "react-query";
import {
  orderStatusTypes,
  ordersObejct,
  addNewBikeForm,
} from "@/types/Gloabal";
import { UsersContextConsumer } from "@/store/AuthProvider";

import { useLocale } from "@/locales";
import {
  ApiAddNewBike,
  ApiDeleteBike,
  ApiEditBike,
  ApiFilterBikes,
  ApiGetAllBikes,
} from "@/utils/globalAPI/Dashboard";
import { SubmitHandler } from "react-hook-form";

export const useBikeList = () => {
  const { localeSuccess, localeErrors } = useLocale();
  const navigate = useNavigate();
  const { adminData } = UsersContextConsumer();
  const toast = ToastConsumer();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalType, setModalType] = useState<orderStatusTypes>();

  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [addBikeLoading, setAddBikeLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchMaker, setSearchMaker] = useState("");
  const [searchEmission, setSearchEmission] = useState("");
  const [bikeId, setBikeId] = useState<string>("");
  const [bikeData, setBikeData] = useState<any>();

  const togelDeactivateModal = () => {
    setShowDeactivateModal(!showDeactivateModal);
  };

  console.log("bikeDataaa", bikeData);

  const {
    data: BikeData,
    isLoading: bikesFetchLoading,
    error,
    refetch,
  } = useQuery(
    [
      "BikeData",
      {
        adminData,
        searchName,
        searchMaker,
        searchEmission,
      },
    ],
    async () => {
      if (!adminData?.signInUserSession?.accessToken?.jwtToken) {
        return; // Return early if the token is not available
      }

      if (searchName || searchMaker || searchEmission) {
        try {
          const filteredBikes = await ApiFilterBikes(
            {
              name: searchName,
              emission: searchEmission,
              maker: searchMaker,
            },
            adminData.signInUserSession.accessToken.jwtToken
          );
          togleShowModalFilterOff();
          return filteredBikes.filterBikes;
        } catch (error: any) {
          toast.error(error.message, "top-right");
          throw error; // Rethrow the error to be caught by the error handling logic
        }
      } else {
        try {
          const allBikes = await ApiGetAllBikes(
            adminData.signInUserSession.accessToken.jwtToken
          );
          return allBikes.bikeList;
        } catch (error: any) {
          toast.error(error.message, "top-right");
          throw error; // Rethrow the error to be caught by the error handling logic
        }
      }
    }
  );

  // let { data: BikeData } = useQuery(
  //   [
  //     "BikeData",
  //     {
  //       adminData,
  //       searchName,
  //       searchMaker,
  //       searchEmission,
  //     },
  //   ],
  //   () =>   searchName || searchMaker || searchEmission
  //   ? ApiFilterBikes(
  //       { name: searchName, emission: searchEmission, maker: searchMaker },
  //       adminData?.signInUserSession?.accessToken?.jwtToken
  //     )

  //   {
  //     enabled: !!tokens && !window.location.pathname.includes("admin"),
  //     refetchOnWindowFocus: false,
  //     refetchOnMount: false,
  //     retry: 0,
  //     onSuccess: (data) => {
  //       if (cookies.rt) {
  //         const isValidAccess = CompareLocalAuth(data);
  //         isValidAccess ? setTokens(data) : setTokens(null);
  //       }
  //       setPageLoading(false);
  //     },
  //     onError: (error: any) => {
  //       if (tokens?.refreshToken && error?.response?.status == 401) {
  //         toast.error(error.message, "top-right");
  //         clearLocalStorageAndCookie();
  //         setTokens(null);
  //       }
  //       setPageLoading(false);
  //     },
  //   }
  // );

  const onAddBike: SubmitHandler<addNewBikeForm> = async (data) => {
    console.log("onAddBike", data);
    if (BikeData.some((bike: any) => bike.name === data.name)) {
      toast.error(localeErrors.ERROR_NAME_ALREADY_ADDED, "top-center");
    } else {
      try {
        setAddBikeLoading(true);
        const res: any = await ApiAddNewBike(
          data,
          adminData?.signInUserSession?.accessToken?.jwtToken
        );
        console.log("response", res);
        if (res?.status === 200) {
          onModal();
          toast.success(localeSuccess.SUCCESS, "top-right");
          setAddBikeLoading(false);
          refetch();
        }
      } catch (err: any) {
        console.log(err);
        onModal();
        setAddBikeLoading(false);
        if (err?.response?.status === 400) {
          toast.error(localeErrors.ERROR_INVALID_EMAIL, "top-center");
        } else toast.error(localeErrors.ERROR_UNKNOWN, "top-center");
      }
    }
  };

  const onEditBike: SubmitHandler<addNewBikeForm> = async (data) => {
    console.log("onEditBike", data);
    const params = {
      name: data?.name,
      emission: data?.emission,
      maker: data?.maker,
    };
    var bikeID = bikeData?._id;

    try {
      setAddBikeLoading(true);
      const res: any = await ApiEditBike(
        params,
        adminData?.signInUserSession?.accessToken?.jwtToken,
        bikeID
      );

      console.log("response", res);
      if (res?.status === 200) {
        setShowEdit(false);
        toast.success(localeSuccess.SUCCESS, "top-right");
        setAddBikeLoading(false);
        refetch();
      }
    } catch (err: any) {
      console.log(err);
      setShowEdit(false);
      setAddBikeLoading(false);
      if (err?.response?.status === 400) {
        toast.error(localeErrors.ERROR_INVALID_EMAIL, "top-center");
      } else toast.error(localeErrors.ERROR_UNKNOWN, "top-center");
    }
  };

  const onDeleteBike = async () => {
    // console.log("onDeleteBike", data);

    try {
      setAddBikeLoading(true);
      const res: any = await ApiDeleteBike(
        bikeId,
        adminData?.signInUserSession?.accessToken?.jwtToken
      );
      console.log("response", res);
      if (res?.status === 200) {
        setShowConfirmationModal(false);
        toast.success(localeSuccess.SUCCESS, "top-right");
        setAddBikeLoading(false);
        refetch();
      }
    } catch (err: any) {
      console.log(err);
      setShowConfirmationModal(false);
      setAddBikeLoading(false);
      if (err?.response?.status === 400) {
        toast.error(localeErrors.ERROR_INVALID_EMAIL, "top-center");
      } else toast.error(localeErrors.ERROR_UNKNOWN, "top-center");
    }
  };

  const onModal = () => {
    setShow(!show);
  };

  const onModalEdit = (id: any) => {
    setBikeData(id);
    setShowEdit(!showEdit);
  };

  const togleShowModalFilter = () => {
    setShowFilter(!showFilter);
  };
  const togleShowModalFilterOff = () => {
    setShowFilter(false);
  };

  const onClickDeleteBike = (id: string) => {
    setBikeId(id);
    setShowConfirmationModal(!showConfirmationModal);
  };

  const onSubmit = (data: any) => {
    setFilterLoading(true);
    setSearchName(data?.name);
    setSearchMaker(data?.maker);
    setSearchEmission(data?.emission);
  };

  const navigateToBikeDetails = (bikeId: string) => {
    navigate("/bike/details?bikeId=" + bikeId);
  };

  console.log("BikeData", BikeData);

  return {
    showConfirmationModal,

    modalType,

    togelDeactivateModal,
    BikeData,
    bikesFetchLoading,
    addBikeLoading,
    onModal,
    show,
    onAddBike,
    togleShowModalFilter,
    showFilter,
    onSubmit,
    navigateToBikeDetails,
    onClickDeleteBike,
    onDeleteBike,
    onModalEdit,
    showEdit,
    bikeData,
    onEditBike,
  };
};

import React, { useEffect, useState } from "react";
import { localeLabels, useLocale } from "../../../locales";
import { TableProps } from "./@types";
import Table from "../../molecules/Table Wrapper";
import LoadingTable from "../Loading Table";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useWidth } from "@/utils/responsiveHook";

/**
 * @props loading will show loading indecator whenever table data load.
 * @props tableHeading contain all th tags in a tr tag.
 * @props tableBody is the actudal data you want to display use tr and td tags according to yours headings.
 * @props showMoreBtn is boolean value that will display more btn @props moreBtnPath will be neccesary with this prop.
 * @props title is Tag of OF Table that will very table to table.
 * @props tableFooter  a bolean value to show next and previose button in the footer.
 * @props nextFunction will be passed when tableFooter is True.
 * @props previousFunction will be passed when tableFooter is true.
 * @props openSearchModal prop will take setFunction that will open serachModal Function
 * @props searchbtnShow thsi will show btn on table top-right position.
 * @props className will sets the Table Wrapper Classes
 * @props tableCalssName will sets on Table Parent, this will be used to sets Scrolling.
 * @props clearFilter will show clear filter btn on table top-right position.
 * @props clearFilterLoading will show loading indicator on clear filter btn.
 * @props onClearFilter will take Function that will run after clearing filter
 */

const TableTemplate = ({
  loading,
  tableHeading,
  tableBody,
  showMoreBtn,
  moreBtnPath,
  title,
  nextFunction,
  previousFunction,
  tableFooter,
  openSearchModal,
  searchbtnShow = false,
  className,
  tableCalssName = "overflow-scroll",
  clearFilter,
  clearFilterLoading,
  onClearfilter,
  tableType,
}: TableProps) => {
  const { localeButtons, localeTitles, localeLinks, localeLabels } =
    useLocale();
  const { width } = useWidth();
  return (
    <div className="flex justify-center w-full">
      <Table
        title={title}
        more={showMoreBtn}
        path={moreBtnPath}
        setSearch={openSearchModal}
        search={searchbtnShow}
        clearFilter={clearFilter}
        onClearfilter={onClearfilter}
        clearFilterLoading={clearFilterLoading}
        className={className}
        tableCalssName={tableCalssName}
      >
        <table className="w-full border-collapse table-auto flex-shrink-0">
          <thead>{tableHeading}</thead>
          <tbody>
            {loading ? <LoadingTable tableType={tableType} /> : tableBody}
            {!loading && (
              <tr
                className={
                  (!loading && tableBody == null) ||
                  !tableBody ||
                  tableBody?.length == 0
                    ? "text-md w-full border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold"
                    : " hidden"
                }
              >
                <td className="py-3 md:lg:py-4 px-5" colSpan={5}>
                  {localeTitles.TITLE_NO_DATA}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {tableFooter && !loading && (
          <div>
            <div className=" text-md w-full border-b-2 border-black last:border-b-0 text-left font-bold ml-5 flex flex-row items-center">
              <button
                className="flex justify-center items-center flex-row  space-x-3 bg-custom-foreground px-2 p-1 cursor-pointer rounded-[5px]"
                onClick={previousFunction}
              >
                <AiOutlineArrowLeft />
                <span className="ml-2">{localeButtons.BUTTON_PREVIOUS}</span>
              </button>

              <button
                onClick={nextFunction}
                className="flex justify-center items-center flex-row cursor-pointer space-x-3 bg-custom-foreground px-2 p-1 rounded-[5px]"
              >
                <span className="mr-2">{localeButtons.BUTTON_NEXT}</span>
                <AiOutlineArrowRight />
              </button>
            </div>
          </div>
        )}
      </Table>
    </div>
  );
};

export default TableTemplate;

TableTemplate.defaultProps = {
  loading: false,
  tableHeading: (
    <tr>
      <th className="text-base font-bold text-center bg-black text-white pt-3 pb-3 pl-2">
        {localeLabels.LABEL_ORDER_DATE}
      </th>
      <th className="text-base font-bold text-center bg-black text-white pt-3 pb-3">
        'localeLabels.LABEL_VENDOR_NAME'
      </th>
      <th className="text-base font-bold text-center bg-black text-white pt-3 pb-3">
        {localeLabels.LABEL_NUM_OF_ITEM}
      </th>

      <th className="text-base font-bold text-center bg-black text-white pt-3 pb-3">
        {localeLabels.LABEL_PAYMENT}
      </th>
      <th
        className="text-base font-bold text-center bg-black text-white pt-3 pb-3"
        colSpan={3}
      >
        {localeLabels.LABEL_ORDER_STATUS}
      </th>
    </tr>
  ),
  tableBody: null,
  showMoreBtn: false,
  moreBtnPath: "",
  title: localeLabels.LABEL_DASHBOARD_LIST,
  nextFunction: () => {},
  previousFunction: () => {},
  tableFooter: false,
  openSearchModal: () => {},
  searchbtnShow: false,
  className: "",
  tableCalssName: "",
  clearFilter: false,
  clearFilterLoading: false,
  onClearfilter: () => {},
};

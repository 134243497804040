export const colorPrimary = "#000000";
export const colorSecondary = "#00b050";
export const colorAlert = "#ff0000";
export const colorDefault = " #141414";
export const colorGray = "#646464";
export const colorWhite = "#ffffff";
export const colorBrown = "#ed7d31";
export const colorBlue = "#0070c0";
export const colorGreen = "#00b050";
export const colorNavy = "#002060";
export const colorYellow = "#ffc000";
export const colorPurple = "#7030a0";
export const colorBgGray = "#f0f0f0";
export const colorButtonGray = "#B7B2B2";
export const colorBackground = "#fafafa";
export const colorBgBlue = "#f5fafe";
export const colorBlack = "#000000";
export const colorLabel = "#000000";
export const btnBlue = "#00A0D2";
export const btnGrey = "#969696";
export const btnColorBlue ="rgba(88, 94, 242, 1)";

import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocale } from "../../../locales";
import {
  btnGrey,
  colorBlue,
  colorPrimary,
  colorWhite,
} from "../../../styles/colors";
import { Button, Text } from "../../atoms";

import InputGroup from "../../molecules/InputGroup";
import type { adminEditAccountFormProps } from "./@types";
import styles from "./AdminEditAccountForm.module.scss";
import Loader from "../../../components/atoms/Loader";
import { ChangePasswordFormField } from "@/types/FormFields";
import { UsersContextConsumer } from "@/store/AuthProvider";

const AdminEditAccountForm = ({
  onSubmit,
  onHide = () => {},
  loading,
  formFields,
}: adminEditAccountFormProps) => {
  const { localeButtons, localeTitles } = useLocale();
  const { accountInfo } = UsersContextConsumer();
  const {
    register,
    handleSubmit,
    formState: { errors: formError },
    reset,
  } = useForm<any>({
    defaultValues: {
      name: accountInfo?.name,
      email: accountInfo?.email,
    },
  });
  // const onSubmit: SubmitHandler<FormField> = (data) => console.log(data);

  return (
    <div className={styles["main"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text
          textStyle={{
            fontWeight: "700",
            fontSize: "24px",
            marginBottom: "0.75rem",
            color: colorPrimary,
            textAlign: "center",
            // width: "max-content",
          }}
        >
          {localeTitles.TITLE_EDIT_ACCOUNT}
        </Text>
        {formFields?.map((field, i) => (
          <InputGroup
            register={register}
            placeholder={field.placeholder}
            type={field.type}
            label={field.label}
            name={field.name}
            // validation={field.validation}
            weight="600"
            error={formError?.[field.name]}
            fontFamily="Montserrat-SemiBold"
          />
        ))}
        <div className={styles["main-btn"]}>
          <Button
            type="submit"
            color={colorWhite}
            backgroundColor={colorPrimary}
            border={colorWhite}
            padding="8px"
            margin="0.5rem 0"
            width="100%"
            height=""
            fontWeight="700"
            buttonStyle={{}}
            disabled={loading}
          >
            {loading ? (
              <Loader text={localeButtons.BUTTON_CONFIRM} color="#fff" />
            ) : (
              localeButtons.BUTTON_CONFIRM
            )}
          </Button>
          <Button
            type="submit"
            color={colorWhite}
            backgroundColor={btnGrey}
            border={colorWhite}
            padding="8px"
            margin="0.5rem 0"
            width="100%"
            height=""
            fontWeight="700"
            buttonStyle={{}}
            disabled={loading}
            onClick={() => {
              onHide();
              reset();
            }}
          >
            {localeButtons.BUTTON_CANCEL}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AdminEditAccountForm;

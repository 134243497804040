import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { SubmitHandler, useForm } from "react-hook-form";
import { ToastConsumer } from "@/store/ToastProvider";
import { UsersContextConsumer } from "@/store/AuthProvider";
import { loginFormFields } from "@/types/FormFields";
import { ApiSignin,ApiDeleteAccount } from "@/utils/globalAPI/deleteAccount";

export const useLogin = () => {
  const toast = ToastConsumer();
  const { admin, loading } = UsersContextConsumer();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<loginFormFields>({ defaultValues: {} });
  // const onSubmit: SubmitHandler<loginFormFields> = (data) => adminSignin(data);

  const navigate = useNavigate();

  const [accestoken,setAccessToken]= useState<string|null>(null)

  const onSubmit:SubmitHandler<loginFormFields> = async (data) => {
    try {
      const response = await ApiSignin(data)
      if (response) {
        debugger
        setAccessToken(response.accessToken)
      }
    } catch (err: any) {
      return toast.error(err?.response?.data?.errorMessage, "top-center");
    }
  }

  const handleDeleteAccount = async() => {
    // dialog alert
   try {
    const result = window.confirm(
      "Are you sure you want to delete your account?"
    )
    if(result)
    {
      // delete account
      const response = await ApiDeleteAccount(accestoken)
      if(response){
        setAccessToken(null)
      }
    }
   }
   catch(err:any){
    return toast.error(err?.response?.data?.errorMessage, "top-center");

   }
  }


  return {
    register,
    handleSubmit,
    onSubmit,
    errors,
    loading,
    accestoken,
    handleDeleteAccount
  };
};

import React from "react";

import { formatStringDateDot } from "../../../utils/days";
import type { TableItemsListProps } from "./@types";
import styles from "./TableItemsList.module.scss";
import { Text } from "@/components/atoms";
import { BsChevronRight, BsPencil } from "react-icons/bs";
import { CancelBtnIcon } from "@/assets/svg";

const TableItemsList = ({
  itemData,
  tableType = "LIST_OF_USERS",
  navigateTo = () => {},
  onClickDeleteBike,
  onClickEdit,
}: TableItemsListProps) => {
  switch (tableType) {
    case "LIST_OF_USERS":
      return (
        <tr className=" text-md px-0 lg:px-2  border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold py-2">
          <td className="pl-8 py-4 px-2 text-left">
            <Text size={"xs"} weight="700">
              {itemData?.nickName}
            </Text>
          </td>

          <td
            className=" py-0 lg:py-4 px-2 text-center cursor-pointer"
            // onClick={navigateTo}
          >
            <Text size={"s"} weight="700">
              {itemData?.province}
            </Text>
          </td>
          <td className=" py-0 lg:py-4 px-2 text-center">
            <Text size={"s"} weight="700">
              {itemData?.gender}
            </Text>
          </td>
          <td
            className=" py-0 lg:py-4 px-2 text-center cursor-pointer"
            // onClick={navigateTo}
          >
            <Text size={"s"} weight="700">
              {itemData?.following}
            </Text>
          </td>
          <td className=" py-0 lg:py-4 px-2 text-center">
            <Text size={"s"} weight="700">
              {itemData?.followers}
            </Text>
          </td>

          <td className="pl-8 py-4 px-2 text-center">
            <Text size={"xs"} weight="700">
              {formatStringDateDot(itemData?.lastLogin as string)}
            </Text>
          </td>

          <td className=" py-0 lg:py-4 px-2 text-center">
            <Text size={"xs"} weight="700">
              {itemData?.numberOfMyBikes}
            </Text>
          </td>

          <td className=" py-0 lg:py-4 px-2 text-center">
            <BsChevronRight onClick={() => navigateTo(itemData?._id)} />
          </td>
        </tr>
      );

    case "LIST_OF_BIKES":
      return (
        <tr className=" text-md px-0 lg:px-2  border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold">
          <td className="pl-8 py-4 px-2 text-left">
            <Text size={"xs"} weight="700">
              {itemData?.name}
            </Text>
          </td>

          <td
            className=" py-0 lg:py-4 px-2 text-center cursor-pointer"
            // onClick={navigateTo}
          >
            <Text size={"s"} weight="700">
              {itemData?.maker}
            </Text>
          </td>
          <td className=" py-0 lg:py-4 px-2 text-center">
            {/* {formatStringDate(iterator.updated_at)} */}
            <Text size={"s"} weight="700">
              {`${itemData?.emission} cc`}
            </Text>
          </td>
          <td className=" py-0 lg:py-4 px-2 text-center">
            {/* {getOrderStatus(iterator.status)} */}
            <Text size={"xs"} weight="700">
              {itemData?.favouritedBy}
            </Text>
          </td>
          <td className=" py-0 lg:py-4 px-2 text-center">
            <div className="flex justify-between">
              <div
                className="cursor-pointer"
                onClick={() => onClickEdit && onClickEdit(itemData)}
              >
                <BsPencil />
              </div>
              <div
                className="cursor-pointer"
                onClick={() =>
                  onClickDeleteBike && onClickDeleteBike(itemData?._id)
                }
              >
                <CancelBtnIcon />
              </div>
            </div>
            {/* <BsChevronRight onClick={() => navigateTo(itemData?._id)} /> */}
          </td>
        </tr>
      );

    default:
      return null;
  }
};

export default TableItemsList;

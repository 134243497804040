import { useNavigate } from "react-router-dom";

import type { dropDownProps } from "./@types";
import styles from "./DropDown.module.scss";
import useComponentVisible from "../../../utils/useComposite";
import { useLocale } from "../../../locales";
import { UsersContextConsumer } from "../../../store/AuthProvider";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Text } from "@/components/atoms";
import { useWidth } from "@/utils/responsiveHook";

const DropDown = ({
  isAdmin = false,
  onEditAccount = () => {},
  onUpdatePassword = () => {},
  signOut,
}: dropDownProps) => {
  const { localeDropdowns } = useLocale();
  const navigate = useNavigate();
  const { width } = useWidth();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const toggle = () => {
    if (isComponentVisible == true) {
      setIsComponentVisible(false);
    } else {
      setIsComponentVisible(true);
    }
  };
  // const editAccount = () => {
  //   setIsComponentVisible(false);
  //   onEditAccount();
  // };
  // const updatePassword = () => {
  //   setIsComponentVisible(false);
  //   onUpdatePassword();
  // };

  const { accountInfo, admin } = UsersContextConsumer();

  const logout = () => {
    // if (isAdmin) {
    // adminSignout();
    // navigate("/signin");
    signOut();
    // }
  };

  return (
    <div ref={ref}>
      <div
        className="flex items-center space-x-1 cursor-pointer"
        onClick={toggle}
      >
        <div className=" font-normal">
          <Text color={width <= 991 ? "white" : "black"} size={"xs"}>
            {accountInfo?.name}
          </Text>
        </div>
        <MdOutlineKeyboardArrowDown size={26} />
      </div>
      {isComponentVisible ? (
        <div className=" font-bold w-28">
          <div className=" bg-[#d9d9d9] p-2 pt-4  z-[50] space-y-3 flex flex-col rounded-lg font-bold  top-10 absolute border-2 border-black  ">
            {/* <div className="cursor-pointer" onClick={editAccount}>
              <Text size={"xs"} weight="700">
                {localeDropdowns.DROPDOWN_EDIT_ACCOUNT}
              </Text>
            </div>
            <div className="cursor-pointer" onClick={updatePassword}>
              <Text size={"xs"} weight="700">
                {localeDropdowns.DROPDOWN_UPDATE_PW}
              </Text>
            </div> */}
            <div className="cursor-pointer" onClick={logout}>
              <Text size={"xs"} weight="700">
                {localeDropdowns.DROPDOWN_LOGOUT}
              </Text>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DropDown;

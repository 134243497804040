import { SubmitHandler, useForm } from "react-hook-form";
import { localeTitles, useLocale } from "../../../locales";
import { colorAlert, colorPrimary, colorWhite } from "../../../styles/colors";
import { Button, Text } from "../../atoms";
import Card from "../../molecules/Card";
import LoginForm from "../../organisms/Login Form";
import type { LoginTemplateTypes } from "./@types";
import styles from "./LoginTemplate.module.scss";
import { REGEXP_PASSWORD } from "@/constants/regexp";

const LoginTemplate = ({
  passwordPattren = REGEXP_PASSWORD,
  loading,
  register,
  handleSubmit,
  onSubmit,
  formError,
  serviceTitle,
  formTitle,
  PathToForgotPassword = "/forgot",
}: LoginTemplateTypes) => {
  const { localeButtons, localeTitles } = useLocale();
  return (
    <div className={styles["login"]}>
      <Card className="max-sm:mx-3">
        <Text
          weight="700"
          size={"l"}
          textStyle={{
            marginBottom: "0.75rem",
            color: colorPrimary,
            textAlign: "center",
            // width: "max-content",
          }}
        >
          {serviceTitle}
        </Text>
        <Text
          weight="700"
          size={"l"}
          textStyle={{
            marginBottom: "0.75rem",
            color: colorPrimary,
            textAlign: "center",
            // width: "max-content",
          }}
        >
          {formTitle}
        </Text>

        <div className={styles["modal-main"]}>
          <LoginForm
            passwordPattren={passwordPattren}
            PathToForgotPassword={PathToForgotPassword}
            formError={formError}
            loading={loading}
            register={register}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
          <div className={styles["modal-buttons"]}></div>
        </div>
      </Card>
    </div>
  );
};

export default LoginTemplate;

LoginTemplate.defaultProps = {
  serviceTitle: localeTitles.TITLE_BRAND,
  formTitle: localeTitles.TITLE_LOGIN_ADMIN,
  loading: false,
  formError: "",
  register: () => {},
  handleSubmit: () => {},
  onSubmit: () => {
    console.log("onSubmit");
  },
};

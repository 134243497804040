import { useState } from "react";
import { useLocale } from "../../../locales";
import {
  btnGrey,
  colorBlack,
  colorBlue,
  colorPrimary,
  colorWhite,
  btnColorBlue,
} from "../../../styles/colors";
import { Button, Text } from "../../atoms";

import type { confirmationProps } from "./@types";
import styles from "./Confirmation.module.scss";
import Loader from "../../../components/atoms/Loader";

const Confirmation = ({
  ModalType,
  onHide = (v, i) => {},
  onConfirm = () => Promise.resolve(),
  title,
}: confirmationProps) => {
  const { localeButtons, localeTitles } = useLocale();

  const [loading, setLoading] = useState(false);

  const OnConfimation = async () => {
    setLoading(true);
    onConfirm()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  return (
    <div className={styles["main"]}>
      <Text size={"l"} textStyle={{ textAlign: "center", fontWeight: "bold" }}>
        {title}
      </Text>
      <div className={styles["main-btn"]}>
        <Button
          type="submit"
          color={colorWhite}
          backgroundColor={colorBlack}
          border={colorWhite}
          padding="8px"
          margin="1rem 0"
          width="100%"
          height=""
          fontWeight="700"
          loading={loading}
          disabled={loading}
          onClick={OnConfimation}
        >
          {loading ? (
            <Loader text={localeButtons.BUTTON_CONFIRM} />
          ) : (
            localeButtons.BUTTON_CONFIRM
          )}
        </Button>
        <Button
          type="submit"
          color={colorWhite}
          backgroundColor={btnGrey}
          border={colorWhite}
          padding="8px"
          margin="1rem 0"
          width="100%"
          height=""
          fontWeight="700"
          disabled={loading}
          onClick={() => {
            onHide("", null);
          }}
        >
          {localeButtons.BUTTON_CANCEL}
        </Button>
      </div>
    </div>
  );
};

export default Confirmation;

import React, { useEffect } from "react";
import { useState } from "react";
import { adminAuthContext } from "./@types";
import { useMutation, useQuery } from "react-query";
import { createCtx } from "../utils";
import { LoginInput, LoginResponse, RegisterInput } from "../../types/Gloabal";
import { useLocation, useNavigate } from "react-router-dom";
import { localeErrors, localeSuccess, useLocale } from "@/locales";
import { ToastConsumer } from "../ToastProvider";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";
import { ApiAuthLoginAdmin } from "@/utils/globalAPI/Admin";

export const [useAuth, SetAuthProvider] = createCtx<adminAuthContext>();

const useAuthCtx = (): adminAuthContext => {
  const toast = ToastConsumer();
  const [cookies, setCookie, removeCookie] = useCookies(["ada", "adr"]);

  const getadminTokenFromCookies = () => {
    const token = cookies.ada;
    const refreshToken = cookies.adr;
    if (token && refreshToken) {
      return {
        accessToken: token as string,
        refreshToken,
      };
    }
    return null;
  };

  const location = useLocation();

  const [admin, setAdmin] = useState<LoginResponse | null>(
    getadminTokenFromCookies()
  );

  // const [loginError, setLoginErrors] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [adminId, setAdminId] = useState<string>("");
  const [adminData, setAdminData] = useState<any>([]);

  

  const navigate = useNavigate();

  return {
    admin,
    // updateAdmin,
    // setLoginErrors,
    // adminSignin,
    // adminSignout,
    // refreshTokensFromCookies,
    loading,
    // loginError,
    // refreTokenOnRequestFailed,
    setAdminData,
    adminData,
  };
};

const AuthAdminProvider = ({ children }: { children: JSX.Element }) => {
  const auth = useAuthCtx();
  // useEffect(() => {
  //   auth.refreshTokensFromCookies();
  // }, []);
  return <SetAuthProvider value={auth}>{children}</SetAuthProvider>;
};

export const UsersContextConsumer = () => {
  const auth = useAuth();
  return auth;
};

export default AuthAdminProvider;

export const PLACEHOLDERS = {
  en: {
    PLACEHOLDER_EMAIL: "Enter you email",
    PLACEHOLDER_PW: "Enter your password",
    PLACEHOLDER_NUM_OF_ITEM: "Enter number of items",
    PLACEHOLDER_SELECT: "Please select",
    PLACEHOLDER_ORDER_DATE_START:
      "Enter start date of period for filtering order date",
    PLACEHOLDER_ORDER_DATE_END:
      "Enter end date of period for filtering order date",
    PLACEHOLDER_ENTER_PHONE: "Please enter your phone number",
    PLACEHOLDER_ENTER_POSTAL_CODE: "Please enter your postal code",
    PLACEHOLDER_ENTER_ADDRESS: "Please enter your address",
    PLACEHOLDER_ENTER_BIKE_NAME: "Please enter your bike name",
    PLACEHOLDER_ENTER_MANUFACTURER: "Please enter a manufacturer",
    PLACEHOLDER_ENTER_EMISSIONS: "Please enter emissions",
    PLACEHOLDER_ENTER_PROVINCE: "Please enter province",
    PLACEHOLDER_ENTER_GENDER: "Please enter gender",
    PLACEHOLDER_ENTER_BIKE: "Please enter bike",
    PLACEHOLDER_ENTER_MAKER: "Please enter maker",
  },
  ja: {
    PLACEHOLDER_ENTER_PROVINCE: "Please enter province",
    PLACEHOLDER_ENTER_GENDER: "Please enter gender",
    PLACEHOLDER_ENTER_BIKE: "Please enter bike",
    PLACEHOLDER_ENTER_MAKER: "Please enter maker",
    PLACEHOLDER_ENTER_EMISSIONS: "排気量を入力してください",
    PLACEHOLDER_ENTER_MANUFACTURER: "メーカーを入力してください",
    PLACEHOLDER_ENTER_BIKE_NAME: "バイク名を入力してください",
    PLACEHOLDER_ENTER_ADDRESS: "住所を入力してください",
    PLACEHOLDER_ENTER_POSTAL_CODE: "郵便番号を入力してください",
    PLACEHOLDER_ENTER_PHONE: "電話番号を入力してください",
    PLACEHOLDER_EMAIL: "メールアドレスを入力してください",
    PLACEHOLDER_PW: "パスワードを入力してください",
    PLACEHOLDER_NUM_OF_ITEM: "発注個数を入力してください",
    PLACEHOLDER_SELECT: "選択してください",
    PLACEHOLDER_ORDER_DATE_START: "期間の開始日",
    PLACEHOLDER_ORDER_DATE_END: "期間の終了日",
  },
};

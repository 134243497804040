import React from "react";
import { colorBlack, colorGray, colorLabel } from "../../../styles/colors";
import { Text } from "../../atoms";
import Input from "../../atoms/Input";
import type { InputGroupProps } from "./@types";
import styles from "./InputGroup.module.scss";

const InputGroup = ({
  validation,
  placeholder = "",
  type = "text",
  register,
  label,
  name,
  readOnly = false,
  disabled = false,
  error,
  weight = "700",
  fontFamily,
  ...props
}: InputGroupProps) => {
  return (
    <div className={styles["input-group"]}>
      <div className={styles.label}>
        <Text
          textStyle={{
            color: colorLabel,
          }}
          className={styles["input-group-text"]}
          weight={"700"}
        >
          {label}
        </Text>
      </div>
      <Input
        validation={validation}
        type={type}
        placeholder={placeholder}
        name={name}
        readOnly={readOnly}
        disabled={disabled}
        register={register}
        onChange={props?.onChange}
        id={props.id}
      />
      {error && (
        <Text color="alert" weight="700">
          {error.message || ""}
        </Text>
      )}
    </div>
  );
};

export default InputGroup;

import { useLocale } from "@/locales";

export const useFilterForm = () => {
  const { localeLabels, localeOrderStatus } = useLocale();

  const StatusList = [
    {
      value: "all",
      label: localeLabels.LABEL_ALL,
    },
    {
      value: "WAIT_FOR_PAYMENT",
      label: localeOrderStatus.WAIT_FOR_PAYMENT,
    },
    {
      value: "WAIT_FOR_SHIPPING",
      label: localeOrderStatus.WAIT_FOR_SHIPPING,
    },
    {
      value: "SHIPPING",
      label: localeOrderStatus.SHIPPING,
    },
    {
      value: "DELIVERY_COMPLETE",
      label: localeOrderStatus.DELIVERY_COMPLETE,
    },
    {
      value: "ORDER_CANCEL",
      label: localeOrderStatus.ORDER_CANCEL,
    },
    {
      value: "ORDER_ISSUED",
      label: localeOrderStatus.ORDER_ISSUED,
    },
  ];
  return {
    StatusList,
  };
};

import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ToastConsumer } from "@/store/ToastProvider";
import { UsersContextConsumer } from "@/store/AuthProvider";
import { useQuery } from "react-query";
import {
  ApiDeactivateUser,
  ApiGetUserBikes,
  ApiGetUserDetails,
} from "@/utils/globalAPI/Dashboard";
import { localeTitles, useLocale } from "@/locales";

export const useUsersDetails = () => {
  const toast = ToastConsumer();
  const navigate = useNavigate();
  const { localeSuccess } = useLocale();
  const { adminData } = UsersContextConsumer();
  const [queryParams] = useSearchParams();
  const [userId, setUserId] = useState(queryParams.get("userId") + "");
  const [show, setShow] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [deActivateLoading, setDeactivateLoading] = useState(false);

  const togelDeactivateModal = () => {
    setShowDeactivateModal(!showDeactivateModal);
  };

  const onModal = () => {
    setShow(!show);
  };

  let {
    data: userDetails,
    isLoading: loadingUserDeatils,
    refetch: refetchUserDetails,
  } = useQuery(
    [
      "userDetails",
      {
        userId,
      },
    ],
    () =>
      ApiGetUserDetails(
        userId as string,
        adminData?.signInUserSession?.accessToken?.jwtToken + ""
      )
        .then((res: any) => {
          return res;
        })
        .catch((err: any) => {
          console.log("error", err);
        })
  );
  let {
    data: userBikes,
    isLoading: loadingUserBikes,
    refetch: refetchUserBikes,
  } = useQuery(
    [
      "userBikes",
      {
        userId,
      },
    ],
    () =>
      ApiGetUserBikes(
        userId as string,
        adminData?.signInUserSession?.accessToken?.jwtToken + ""
      )
        .then((res: any) => {
          return res;
        })
        .catch((err: any) => {
          console.log("error", err);
        })
  );

  const navigateToUserDetails = (userId: string) => {
    navigate("/users/details?userId=" + userId);
  };

  const DeActivateUser = () => {
    // if (userDetails?.profile?.status === "inactive") {
    //   toast.error(localeTitles.TITLE_ALREADY_INACTIVE, "top-right");
    // } else {
    const params = {
      status: "inactive",
    };
    setDeactivateLoading(true);
    return ApiDeactivateUser(
      userDetails?.profile?._id,
      params,
      adminData?.signInUserSession?.accessToken?.jwtToken
    )
      .then((res) => {
        if (res.success) {
          toast.success(localeSuccess.SUCCESS, "top-right");
          refetchUserDetails();
          setShowDeactivateModal(false);
          setDeactivateLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.error(err.response.data.errorMessage, "top-right");
        }
        // else refreTokenOnRequestFailed(err, () => DeActivateUser());
        // setDeactivateLoading(false);
      });
  };
  // };

  // console.log("userDetails", userDetails);

  useEffect(() => {
    if (!userId) {
      alert(queryParams.get("id"));
      setUserId(queryParams.get("id") + "");
    }
  }, [userId]);

  return {
    show,
    onModal,
    userDetails,
    loadingUserDeatils,
    navigateToUserDetails,
    showDeactivateModal,
    togelDeactivateModal,
    deActivateLoading,
    DeActivateUser,
    userBikes,
    loadingUserBikes,
  };
};

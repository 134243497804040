export const STATUS = {
  en: {
    STATUS_WAITING_PAYMENT: "Waiting for Payment",
    STATUS_WAITING_DELIVERY: "Waiting for Delivery",
    STATUS_SHIPPING: "Shipping",
    STATUS_DELIVERY_COMPLETED: "Delivery Complete",
    STATUS_ORDER_CANCELLED: "Order Cancelled",
    STATUS_ORDER_ISSUED: "Order Issued",
  },
  ja: {
    STATUS_WAITING_PAYMENT: "支払い待ち",
    STATUS_WAITING_DELIVERY: "発送待ち",
    STATUS_SHIPPING: "配送中",
    STATUS_DELIVERY_COMPLETED: "納品完了",
    STATUS_ORDER_CANCELLED: "キャンセル",
    STATUS_ORDER_ISSUED: "不具合",
  },
};

import { Text } from "@/components/atoms";
import React from "react";
import { useLocale } from "../../../locales";
import type { tableHeaderProps } from "./@types";

const TableHeader = ({ tableType = "LIST_OF_USERS" }: tableHeaderProps) => {
  const { localeLabels, localeTitles } = useLocale();
  switch (tableType) {
    case "LIST_OF_USERS":
      return (
        <tr>
          <th className=" font-bold text-left bg-black  pt-3 pb-3 pl-8">
            <Text color="white" size={"s"} weight="700">
              {localeLabels.LABEL_NICKNAME}
            </Text>
          </th>
          <th className=" font-bold text-center bg-black text-white pt-3 pb-3 pl-4">
            <Text color="white" size={"s"} weight="700">
              {localeLabels.LABEL_PROVINCE}
            </Text>
          </th>
          <th className=" font-bold text-center bg-black text-white pt-3 pb-3">
            <Text color="white" size={"s"} weight="700">
              {localeLabels.LABEL_GENDER}
            </Text>
          </th>
          <th className=" font-bold text-center bg-black text-white pt-3 pb-3">
            <Text color="white" size={"s"} weight="700">
              {localeLabels.LABEL_NUM_OF_FOLLOWS}
            </Text>
          </th>
          <th className=" font-bold text-center bg-black text-white pt-3 pb-3">
            <Text color="white" size={"s"} weight="700">
              {localeLabels.LABEL_NUM_OF_FOLLOWERSS}
            </Text>
          </th>
          <th className=" font-bold text-center bg-black text-white pt-3 pb-3">
            <Text color="white" size={"s"} weight="700">
              {localeLabels.LABEL_LAST_LOGIN}
            </Text>
          </th>
          <th className=" font-bold text-center bg-black text-white pt-3 pb-3">
            <Text color="white" size={"s"} weight="700">
              {localeLabels.LABEL_NUM_OF_MY_BIKES}
            </Text>
          </th>
          <th
            className=" font-bold text-center bg-black text-white pt-3 pb-3"
            colSpan={1}
          ></th>
        </tr>
      );
    case "LIST_OF_BIKES":
      return (
        <tr>
          <th className=" font-bold text-left bg-black  pt-3 pb-3 pl-8">
            <Text color="white" size={"s"} weight="700">
              {localeLabels.LABEL_BIKE_NAME}
            </Text>
          </th>
          <th className=" font-bold text-center bg-black text-white pt-3 pb-3 pl-4">
            <Text color="white" size={"s"} weight="700">
              {localeLabels.LABEL_MAKER}
            </Text>
          </th>
          <th className=" font-bold text-center bg-black text-white pt-3 pb-3">
            <Text color="white" size={"s"} weight="700">
              {localeLabels.LABEL_EMISSION}
            </Text>
          </th>
          <th className=" font-bold text-center bg-black text-white pt-3 pb-3">
            <Text color="white" size={"s"} weight="700">
              {localeLabels.LABEL_NUM_OF_FAV}
            </Text>
          </th>
          <th
            className=" font-bold text-center bg-black text-white pt-3 pb-3"
            colSpan={1}
          ></th>
        </tr>
      );
    default:
      return null;
  }
};

//   }
// };

export default TableHeader;

export const DROPDOWNS = {
  en: {
    DROPDOWN_EDIT_ACCOUNT: "Edit Account",
    DROPDOWN_UPDATE_PW: "Update Password",
    DROPDOWN_LOGOUT: "Logout",
  },
  ja: {
    DROPDOWN_EDIT_ACCOUNT: "アカウントの編集",
    DROPDOWN_UPDATE_PW: "パスワードを変更",
    DROPDOWN_LOGOUT: "ログアウト",
  },
};

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UsersContextConsumer } from "@/store/AuthProvider";
import { ToastConsumer } from "@/store/ToastProvider";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  ChangePasswordFormField,
  adminEditAccountFormFields,
} from "@/types/FormFields";

import { useLocale } from "@/locales";

export const useLayout = (isAdmin: boolean, isProtected: boolean) => {
  const { localeErrors, localeSuccess } = useLocale();
  const [show, setShow] = React.useState(false);
  const [editAccountModal, setEditAccountModal] = React.useState(false);
  const [updatePasswordModal, setUpdatePasswordModal] = React.useState(false);
  const toast = ToastConsumer();

  const dashboardPath = "/";

  const onEditAccount = () => {
    setEditAccountModal(!editAccountModal);
  };
  const onUpdatePassword = () => {
    setUpdatePasswordModal(!updatePasswordModal);
  };

  const { adminData, setAdminData, accountInfo, updateAdmin, admin } =
    UsersContextConsumer();
  const [loading, setLoading] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  const {
    register: accountRegister,
    reset: resetAccountForm,
    handleSubmit: accountHandleSubmit,
    formState: { errors: accountErrors },
  } = useForm<adminEditAccountFormFields>({
    defaultValues: {
      name: accountInfo?.name,
      email: accountInfo?.email,
    },
  });
  const onSubmitAccountForm: SubmitHandler<adminEditAccountFormFields> = (
    data
  ) => {
    if (isAdmin) {
      editAdminAccount(data);
    }
  };

  const editAdminAccount = async (data: adminEditAccountFormFields) => {
    if (data.name == "" && data.email == "") return;
    // try {
    //   setLoading(true);
    //   const response = await editAccounInfoAdmin(
    //     data,
    //   );
    //   if (response.status === 200) {
    //     setEditAccountModal(false);
    //     setLoading(false);
    //     toast.success(localeSuccess.SUCCESS_EDIT_ACCOUNT, "top-right");
    //   } else {
    //     toast.error(response.data.message, "top-right");
    //     setLoading(false);
    //   }
    // } catch (err) {
    //   console.log(err);
    //   toast.error(localeErrors.ERROR_UNKNOWN, "top-right");
    //   setLoading(false);
    //   refreTokenOnRequestFailed(err, () => editAdminAccount(data));
    // }
  };
  console.log("adminData", adminData);
  return {
    show,
    setShow,
    editAccountModal,
    setEditAccountModal,
    updatePasswordModal,
    setUpdatePasswordModal,
    dashboardPath,
    onEditAccount,
    onUpdatePassword,

    accountRegister,
    accountHandleSubmit,
    accountErrors,
    onSubmitAccountForm,
    resetAccountForm,
    loading,
    updateAdmin,
    adminData,
    setAdminData,
  };
};

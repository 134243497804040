export const LABELS = {
  en: {
    LABEL_FULLNAME: "Full name",
    LABEL_EMAIL: "Email",
    LABEL_PW: "Password",
    LABEL_CPW: "Confirm Password",
    LABEL_NEW_PW: "New Password",
    LABEL_NEW_CPW: "Confirm New Password",
    LABEL_MONTHLY_ORDERS: "Total Monthly Orders",
    LABEL_TOTAL_BEFORE_SHIPPING: "Unshipped Orders",
    LABEL_TOTAL_ISSUES: "Issued Orders",
    LABEL_TOTAL_SHIPPING: "Shipping Orders",
    LABEL_DASHBOARD_LIST: "Recent Orders",
    LABEL_ORDER_DATE: "Order Date",
    LABEL_NUM_OF_ITEM: "Number of Items",
    LABEL_ORDER_STATUS: "Status",
    LABEL_VIEW_MORE: "View More",
    LABEL_PAYMENT_TYPE: "Payment Type",
    LABEL_ADMIN_NAME: "Admin Name",
    LABEL_NUM_OF_ORDERS: "Number of Orders",
    LABEL_PHONE: "Phone Number",
    LABEL_ADDRESS: "Address",
    LABEL_CREATED_AT: "Created At",
    LABEL_ORDER_LIST: "Order List",
    LABEL_FILTER: "Filter",
    LABEL_STATUS_ORDER_STATUS: "Order Status",
    LABEL_SEARCH: "search",
    LABEL_ALL: "All",
    LABEL_ORDERED: "Ordered",
    LABEL_SHIPPED: "Shipped",
    LABEL_ISSUE_OCCURED: "Issue Occured",
    LABEL_DELIVERED: "Delivered",
    LABEL_CANCELLED: "Cancelled",
    LABEL_Y: "Y",
    LABEL_M: "M",
    LABEL_Next: "Next",
    LABEL_PREV_MONTH: "Prev",
    LABEL_NEXT_MONTH: "Next",
    LABEL_TOTAL_DEFECTS: "Total Defects",
    LABEL_TOTAL_UNSHIPPED_ITEMS: "Total unshipped items",
    LABEL_EMAIL_FOR_CONFIRM: "Email address (for confirmation)",
    LABEL_PW_FOR_CONFIRM: "For password confirmation)",
    LABEL_POSTAL_CODE: "Postal code (no hyphen)",
    LABEL_CONFIRM_PW: "Confirm Password",
    LABEL_PIECES: "Pcs",
    LABEL_PAYMENT: "Payment",
    LABEL_NICKNAME: "Nick name",
    LABEL_PROVINCE: "Province",
    LABEL_GENDER: "Gender",
    LABEL_NUM_OF_FOLLOWS: "Number of follows",
    LABEL_NUM_OF_FOLLOWERSS: "Number of followers",
    LABEL_LAST_LOGIN: "Last login",
    LABEL_MY_BIKE: "My bikes",
    LABEL_BIKE_NAME: "Bike name",
    LABEL_MAKER: "Maker",
    LABEL_EMISSION: "Emission",
    LABEL_NUM_OF_FAV: "Number of favorites",
    LABEL_NUM_OF_REGISTERED_USERS: "Number of registered users",
    LABEL_NUM_OF_MY_BIKES: "Num of my bikes",
    LABEL_ADD_BIKE: "Add bike",
    LABEL_MYBIKE: "MY_BIKE",
    LABEL_FOLLOW: "Follow",
    LABEL_MAINTAINCE: "Maintance",
    LABEL_TOURING: "Touring",
    LABEL_GOURMET: "Gourmet",
    LABEL_RACE_CIRCUIT: "Race / Circuit",
    LABEL_APPAREL: "Apparel",
  },
  ja: {
    LABEL_MYBIKE: "マイバイク",
    LABEL_FOLLOW: "フォロー中",
    LABEL_MAINTAINCE: "整備/カスタム",
    LABEL_TOURING: "ツーリング",
    LABEL_GOURMET: "グルメ",
    LABEL_RACE_CIRCUIT: "レース/サーキット",
    LABEL_APPAREL: "アパレル",
    LABEL_ADD_BIKE: "ユーザーリスト",
    LABEL_NUM_OF_MY_BIKES: "登録バイク数",
    LABEL_NUM_OF_REGISTERED_USERS: "登録ユーザー数",
    LABEL_NUM_OF_FAV: "ガレージ登録者数",
    LABEL_EMISSION: "排気量",
    LABEL_MAKER: "メーカー",
    LABEL_BIKE_NAME: "バイク名",
    LABEL_MY_BIKE: "Myバイク",
    LABEL_LAST_LOGIN: "フォロワー数",
    LABEL_NUM_OF_FOLLOWS: "フォロー数",
    LABEL_NUM_OF_FOLLOWERSS: "フォロワー数",
    LABEL_GENDER: "性別",
    LABEL_PROVINCE: "都道府県",
    LABEL_NICKNAME: "ニックネーム",
    LABEL_PIECES: "個",
    LABEL_POSTAL_CODE: "郵便番号（ハイフンなし）",
    LABEL_PW_FOR_CONFIRM: "パスワード（確認用）",
    LABEL_EMAIL_FOR_CONFIRM: "メールアドレス（確認用）",
    LABEL_TOTAL_UNSHIPPED_ITEMS: "未発送数合計",
    LABEL_TOTAL_DEFECTS: "不具合数合計",
    LABEL_NEXT_MONTH: "翌月",
    LABEL_PREV_MONTH: "前月",
    LABEL_Y: "年",
    LABEL_M: "月",
    LABEL_Next: "Next",
    LABEL_ALL: "All",
    LABEL_ORDERED: "Ordered",
    LABEL_SHIPPED: "Shipped",
    LABEL_ISSUE_OCCURED: "Issue Occured",
    LABEL_DELIVERED: "Delivered",
    LABEL_CANCELLED: "Cancelled",
    LABEL_SEARCH: "絞り込み",
    LABEL_FULLNAME: "名前",
    LABEL_ACCOUNT_HOLDER_NAME: "Account Holder Name",
    LABEL_EMAIL: "メールアドレス",
    LABEL_PW: "パスワード",
    LABEL_CPW: "パスワード(確認用)",
    LABEL_NEW_PW: "新しいパスワード",
    LABEL_NEW_CPW: "新しいパスワード(確認用)",
    LABEL_MONTHLY_ORDERS: "発注数",
    LABEL_TOTAL_BEFORE_SHIPPING: "未発送数",
    LABEL_TOTAL_ISSUES: "不具合数",
    LABEL_TOTAL_SHIPPING: "配達中合計",
    LABEL_DASHBOARD_LIST: "最近の発注",
    LABEL_ORDER_DATE: "発注日",
    LABEL_NUM_OF_ITEM: "発注個数",
    LABEL_ORDER_STATUS: "ステータス",
    LABEL_VIEW_MORE: "もっと見る",
    LABEL_PAYMENT_TYPE: "支払い方法",
    LABEL_ADMIN_NAME: "管理者名",
    LABEL_NUM_OF_ORDERS: "発注回数",
    LABEL_PHONE: "電話番号",
    LABEL_ADDRESS: "住所",
    LABEL_CREATED_AT: "作成日",
    LABEL_ORDER_LIST: "注文一覧",
    LABEL_FILTER: "絞り込み",
    LABEL_STATUS_ORDER_STATUS: "発注ステータス",
    LABEL_CONFIRM_PW: "パスワード(確認用)",
    LABEL_PAYMENT: "支払い",
  },
};

import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import type { sidebarProps } from "./@types";
import styles from "./Sidebar.module.scss";
import { useLocale } from "../../../locales";
import { Text } from "@/components/atoms";

const Sidebar = ({ dashboard = "/", ...props }: sidebarProps) => {
  const { localeTitles, localeLinks } = useLocale();
  const navigate = useNavigate();

  const location = useLocation();

  // const accessType = () => {
  //   if (
  //     location.pathname.includes("admin") ||
  //     (location.pathname.length === 1 && location.pathname.startsWith("/"))
  //   )
  //     return "admin";
  //   else if (location.pathname.includes("agency")) return "agency";
  //   else return "vendor";
  // };
  const titleType = () => {
    if (
      location.pathname.includes("users") ||
      (location.pathname.length === 1 && location.pathname.startsWith("/"))
    )
      return localeLinks.LINK_LIST_OF_USERS;
    else if (location.pathname.match("/users/details"))
      return localeLinks.LINK_ORDERS;
    else if (location.pathname.match("/bikes"))
      return localeLinks.LINK_LIST_OF_BIKES;
    else if (location.pathname.match("/categories"))
      return localeLinks.LINK_CHARTS_SCREEN;
  };
  return (
    <div className="lg:w-[230px]">
      <div className="w-[100%] bg-black min-h-screen text-white  hidden lg:block">
        <NavLink to={dashboard?.length != 0 ? dashboard : "/"}>
          <div
            style={{ fontSize: "1.5rem" }}
            className="font-bold leading-6  align-middle  pt-3 pl-4 pb-3  cursor-pointer"
          >
            <Text color="white" size={"m"} weight="700">
              {localeTitles.TITLE_BRAND}
            </Text>
            <div className="text-[14px] font-bold">
              <Text color="white" weight="700" size={"xs"}>
                {titleType()}
              </Text>
            </div>
          </div>
        </NavLink>

        <div className="mt-20 flex flex-col">
          <>
            <NavLink
              to={"/users"}
              className={({ isActive }) =>
                isActive
                  ? "border-l-2  h-10 bg-[#646464] py-2 leading-5 cursor-pointer  pl-12 w-[100%] font-bold"
                  : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer"
              }
            >
              <Text color="white" weight="500" size={"s"}>
                {localeLinks.LINK_LIST_OF_USERS}
              </Text>
            </NavLink>

            <NavLink
              to="/bikes"
              className={({ isActive }) =>
                isActive
                  ? "border-l-2 w-[100%] h-10 bg-[#646464] py-2 leading-5 cursor-pointer  pl-12 font-bold"
                  : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer"
              }
            >
              <Text color="white" weight="500" size={"s"}>
                {localeLinks.LINK_LIST_OF_BIKES}
              </Text>
            </NavLink>

            <NavLink
              to={"/categories"}
              className={({ isActive }) =>
                isActive
                  ? "border-l-2 w-[100%] h-10 bg-[#646464] py-2 leading-5 cursor-pointer  pl-12 font-bold"
                  : "w-[100%] h-10 pl-8 py-2 leading-5 cursor-pointer"
              }
            >
              <Text color="white" weight="500" size={"s"}>
                {localeLinks.LINK_CHARTS_SCREEN}
              </Text>
            </NavLink>
          </>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

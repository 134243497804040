import React from "react";
import {
  colorBlack,
  colorGray,
  colorLabel,
  colorWhite,
} from "../../../styles/colors";
import { Text } from "../../atoms";
import type { addButtonProps } from "./@types";
import styles from "./AddButton.module.scss";

const AddButton = ({
  text,
  svg,
  color = "BLUE",
  onClick,
  ...props
}: addButtonProps) => {
  return (
    <div
      className={`${styles["icon-button"]} ${styles[`icon-button-${color}`]} cursor-pointer`}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {svg}
      <Text
        textStyle={{
          color: colorWhite,
          fontWeight: "700",
          fontSize: "20px",
          marginLeft: "-16px",
        }}
      >
        {text}
      </Text>
    </div>
  );
};

export default AddButton;

import React from "react";
import { loadingProps } from "./@types";
import styles from "./Loader.module.scss";

const Loader = ({ color="#323435",...props }:React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={styles["load-1"]} {...props}>
      <div style={{ background: color }} className={styles["line"]}></div>
      <div style={{ background: color }} className={styles["line"]}></div>
      <div style={{ background: color }} className={styles["line"]}></div>
    </div>
  );
};

export default Loader;

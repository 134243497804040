import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocale } from "../../../locales";
import { btnGrey, colorPrimary, colorWhite } from "../../../styles/colors";
import { Button, Text } from "../../atoms";
import SelectGroup from "../../molecules/Select Group";
import type { filterFormProps } from "./@types";
import styles from "./FilterForm.module.scss";
import InputGroup from "../../../components/molecules/InputGroup";
import { useFilterForm } from "./hook";
import Loader from "@/components/atoms/Loader";
import Input from "@/components/atoms/Input";

const FilterForm = ({
  onClickClearFilter = () => {},
  onSubmit,
  loading,
  type = "users",
}: filterFormProps) => {
  const {
    localeLabels,
    localeButtons,
    localePlaceholders,
    localeTitles,
    localeErrors,
  } = useLocale();
  type FormField = {
    validation?: object;
    label: string;
    placeholder: string;
    type: "email" | "password" | "text";
    name: string;
  };
  const bikeFilterFeilds: FormField[] = [
    {
      // validation: {
      //   required: localeErrors.ERROR_PLEASE_TYPE,
      //   minlength: 1,
      // },
      name: "bike",
      label: localeLabels.LABEL_BIKE_NAME,
      placeholder: localePlaceholders.PLACEHOLDER_ENTER_BIKE_NAME,
      type: "text",
    },
    {
      // validation: {
      //   required: localeErrors.ERROR_PLEASE_TYPE,
      //   minlength: 1,
      // },
      name: "maker",
      label: localeLabels.LABEL_MAKER,
      placeholder: localePlaceholders.PLACEHOLDER_ENTER_MAKER,
      type: "text",
    },
    {
      // validation: {
      //   required: localeErrors.ERROR_PLEASE_TYPE,
      //   minlength: 1,
      // },
      name: "emission",
      label: localeLabels.LABEL_EMISSION,
      placeholder: localePlaceholders.PLACEHOLDER_ENTER_EMISSIONS,
      type: "text",
    },
  ];
  const userFilterFeilds: FormField[] = [
    {
      // validation: {
      //   required: localeErrors.ERROR_INVALID_PROVINCE,
      //   minlength: 2,
      // },
      name: "province",
      label: localeLabels.LABEL_PROVINCE,
      placeholder: localePlaceholders.PLACEHOLDER_ENTER_PROVINCE,
      type: "text",
    },
    {
      // validation: {
      //   required: localeErrors.ERROR_PLEASE_TYPE,
      //   minlength: 1,
      // },
      name: "gender",
      label: localeLabels.LABEL_GENDER,
      placeholder: localePlaceholders.PLACEHOLDER_ENTER_GENDER,
      type: "text",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { StatusList } = useFilterForm();
  return (
    <div className={styles["main"]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text
          textStyle={{
            fontWeight: "700",
            fontSize: "24px",
            marginBottom: "0.75rem",
            color: colorPrimary,
            textAlign: "center",
            // width: "max-content",
          }}
        >
          {localeTitles.TITLE_FILTER}
        </Text>

        {type === "users" &&
          userFilterFeilds.map((field, i) => (
            <InputGroup
              register={register}
              placeholder={field.placeholder}
              type={field.type}
              label={field.label}
              name={field.name}
              error={errors[field.name]}
              validation={field.validation}
              weight="600"
              fontFamily="Montserrat-SemiBold"
            />
          ))}
        {type === "bikes" &&
          bikeFilterFeilds.map((field, i) => (
            <InputGroup
              register={register}
              placeholder={field.placeholder}
              type={field.type}
              label={field.label}
              name={field.name}
              error={errors[field.name]}
              validation={field.validation}
              weight="600"
              fontFamily="Montserrat-SemiBold"
            />
          ))}

        <div className={styles["main-btn"]}>
          <Button
            type="submit"
            color={colorWhite}
            backgroundColor={colorPrimary}
            border={colorWhite}
            padding="8px"
            margin="1rem 0"
            width="100%"
            height=""
            fontWeight="700"
            buttonStyle={{}}
            // disabled={loading}
          >
            {!loading ? (
              localeButtons.BUTTON_APPLY_FILTER
            ) : (
              <Loader text={localeButtons.BUTTON_APPLY_FILTER} color="#fff" />
            )}
          </Button>
          <Button
            // disabled={loading}
            color={colorWhite}
            backgroundColor={btnGrey}
            border={colorWhite}
            padding="8px"
            margin="1rem 0"
            width="100%"
            height=""
            fontWeight="700"
            buttonStyle={{}}
            onClick={onClickClearFilter}
          >
            {localeButtons.BUTTON_CLEAR_FILTER}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FilterForm;

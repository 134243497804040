import React, { useEffect, useState } from "react";
import type { CategoryChartProps } from "./@types";
import styles from "./CategoryChart.module.scss";
import { useWidth } from "@/utils/responsiveHook";

import ChartComponent from "@/components/organisms/ChartComponent";
import { useCategoryChartList } from "./hook";

const CategoryChart = ({ heading }: CategoryChartProps) => {
  const {
    categoriesFetchLoading,
    getMonthlyData,
    postMonthLyDataLoading,
    BlogMonthLyDataLoading,
    threadMonthLyDataLoading,
    touringMonthLyDataLoading,
    chartOptions,
    chartListoptions,
    postData,
    touringData,
    blogData,
    threadData,
  } = useCategoryChartList();

  // console.log("touringData", touringData);
  // console.log("postData", postData);
  // console.log("blogData", blogData);
  // console.log("threadData", threadData);

  const { width } = useWidth();

  return (
    <div className="">
      <div className={styles["user-detail"]}>
        <ChartComponent
          getMonthlyData={getMonthlyData}
          data={postData}
          options={chartOptions}
          type="bar"
          heading="POST"
          loading={postMonthLyDataLoading}
        />
        <ChartComponent
          getMonthlyData={getMonthlyData}
          data={blogData}
          options={chartOptions}
          type="bar"
          heading="BLOGS"
          loading={BlogMonthLyDataLoading}
        />
        <ChartComponent
          getMonthlyData={getMonthlyData}
          data={threadData}
          options={chartOptions}
          type="bar"
          heading="THREADS"
          loading={threadMonthLyDataLoading}
        />
        <ChartComponent
          getMonthlyData={getMonthlyData}
          data={touringData}
          options={chartListoptions}
          type="line"
          heading="TOURING"
          loading={touringMonthLyDataLoading}
        />
      </div>
    </div>
  );
};

export default CategoryChart;

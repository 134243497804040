import React from "react";

import { formatStringDateSlash } from "../../../utils/days";
import { GetFunctionStatus } from "../../templates/CommonTable/GetStatusLablels";
import type { TableItemsListProps } from "./@types";
import styles from "./OrderTableItems.module.scss";
import { Text } from "@/components/atoms";
import { localeLabels } from "@/locales";
import ArrowRightIcon from "@/assets/svg/ArrowRightIcon";

const OrderTableItemsMobile = ({
  itemData,
  tableType = "LIST_OF_USERS",
  navigateTo = () => {},
}: TableItemsListProps) => {
  console.log("itemData", itemData);
  switch (tableType) {
    case "LIST_OF_USERS":
      return (
        <tr className=" text-md px-0 lg:px-2  border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold py-2 h-[70px]">
          <td className="py-4 px-2 text-center" colSpan={1}>
            <div className="flex font-bold">
              <Text size={"xs"} textStyle={{ fontWeight: "bold" }}>
                {itemData?.nickName}
              </Text>
            </div>
          </td>

          <td className=" py-0 lg:py-4 px-1">
            <div className="flex ">
              <Text size={"xs"} textStyle={{ fontWeight: "bold" }}>
                {itemData?.province}
              </Text>
            </div>
          </td>
          <td
            className=" py-0 lg:py-4 px-0 text-center"
            onClick={() => navigateTo && navigateTo(itemData?._id)}
          >
            <div className="flex justify-center">
              <ArrowRightIcon />
            </div>
          </td>
        </tr>
      );

    case "LIST_OF_BIKES":
      return (
        <tr className=" text-md px-0 lg:px-2  border-b-2 border-black last:border-b-0  hover:bg-custom-foreground text-left font-bold py-2 h-[70px]">
          <td className="pl-2 py-4 px-2 text-center" colSpan={1}>
            <div className="flex justify-between ">
              <Text size={"xxs"}>{itemData?.name}</Text>
            </div>
          </td>
          <td className="pl-2 py-4 px-2 text-center" colSpan={1}>
            <div className="flex justify-between ">
              <Text size={"xxs"}>{itemData?.maker}</Text>
            </div>
          </td>
          <td className="pl-2 py-4 px-2 text-center" colSpan={1}>
            <div className="flex justify-between ">
              <Text size={"xxs"}>{itemData?.emission}</Text>
            </div>
          </td>

          <td
            className=" py-0 lg:py-4 lg:px-2 text-center"
            onClick={() => navigateTo(itemData?.order_id)}
          >
            <div className="flex justify-center">
              <ArrowRightIcon />
            </div>
          </td>
        </tr>
      );

    default:
      return null;
  }
};

export default OrderTableItemsMobile;
